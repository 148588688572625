import React, {
	type ReactNode,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
	type MouseEvent,
} from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_CardList_fragment$key as CardListType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_CardList_fragment.graphql';
import type { main_issueNavigator_CardWithSelectedIssue_fragment$key as CardWithSelectedIssueFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_CardWithSelectedIssue_fragment.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getMaxIssuesPerPage } from '../../../../common/constants';
import { isNonNullish, isNormalClick } from '../../../../common/utils';
import { markOnce, marks } from '../../../../common/utils/performance-analytics';
import {
	useFocusedIssueIndex,
	useSelectedIssueStateOldActions,
} from '../../../../controllers/selected-issue-state-old';
import { useSelectedIssueIndex } from '../../../../controllers/selected-issue/facade.tsx';
import {
	useIsSelectedIssue,
	useSelectedIssueActions,
} from '../../../../controllers/selected-issue/hooks.tsx';
import CardEmptyList from './card-empty-list';
import CardSkeleton from './card-skeleton';
import EmptyCard from './card/empty-card/index.tsx';
import Card, { type Props as CardProps } from './card/main.tsx';
import KeyboardShortcuts from './keyboard-shortcuts';
import messages from './messages';

export type CardListProps = {
	fragment: CardListType;
	loading: boolean;
	onNavigateToIssue: (issueKey: string) => void;
};

type CardWithAnalyticsProps = {
	fragment: CardProps['fragment'] | undefined;
	index?: number;
	isFocused: boolean;
	isSelected: boolean;
	onFocus: () => void;
	onBlur: () => void;
};

const BLANK_CARD_ANALYTICS_ID = 'blankIssueCard';
const ISSUE_CARD_ANALYTICS_ID = 'issueCard';

type CardWithSelectedIssueProps = {
	fragment: CardWithSelectedIssueFragment;
	isFocused: boolean;
	onFocus: (issueKey: string) => void;
	onBlur: () => void;
};
const CardWithSelectedIssue = ({
	fragment,
	isFocused,
	onFocus,
	onBlur,
}: CardWithSelectedIssueProps) => {
	const fragmentData = useFragment<CardWithSelectedIssueFragment>(
		graphql`
			fragment main_issueNavigator_CardWithSelectedIssue_fragment on JiraIssue {
				key
				...main_issueNavigator_Card_fragment
			}
		`,
		fragment,
	);
	const { key } = fragmentData;

	const isSelected = useIsSelectedIssue(key);
	const { setSelectedIssueByKey } = useSelectedIssueActions();

	const onCardFocus = useCallback(() => {
		onFocus(key);
		setSelectedIssueByKey(key, { shouldDebounce: true });
	}, [key, onFocus, setSelectedIssueByKey]);

	return (
		<CardWithAnalytics
			fragment={fragmentData}
			isFocused={isFocused}
			isSelected={isSelected}
			onFocus={onCardFocus}
			onBlur={onBlur}
		/>
	);
};

type CardWithSelectedIssueStateOldProps = {
	fragment: CardProps['fragment'] | undefined;
	index: number;
};

const CardWithSelectedIssueStateOld = ({ index, fragment }: CardWithSelectedIssueStateOldProps) => {
	const selectedIssueIndex = useSelectedIssueIndex();
	const { setSelectedIssueByIndex } = useSelectedIssueStateOldActions();
	const [focusedIssueIndex, { setFocusedIssueByIndex, resetFocusedIssue }] = useFocusedIssueIndex();
	const isSelected = selectedIssueIndex === index;
	const isFocused = focusedIssueIndex === index;

	const onCardFocus = useCallback(() => {
		setFocusedIssueByIndex(index);
		setSelectedIssueByIndex(index, true);
	}, [index, setFocusedIssueByIndex, setSelectedIssueByIndex]);

	return (
		<CardWithAnalytics
			fragment={fragment}
			index={index}
			isFocused={isFocused}
			isSelected={isSelected}
			onFocus={onCardFocus}
			onBlur={resetFocusedIssue}
		/>
	);
};

const CardWithAnalytics = ({
	index,
	isFocused,
	isSelected,
	fragment,
	onFocus,
	onBlur,
}: CardWithAnalyticsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cardRef = useRef<HTMLAnchorElement>(null);

	useLayoutEffect(() => {
		if (isSelected) {
			// Scroll to the card whenever it is selected and focus on it
			cardRef.current?.scrollIntoView && cardRef.current?.scrollIntoView({ block: 'nearest' });
			isFocused && cardRef.current?.focus();
		}
	}, [isSelected, isFocused]);

	const onClickWithAnalytics = useCallback(
		(event: MouseEvent) => {
			const id = fragment ? ISSUE_CARD_ANALYTICS_ID : BLANK_CARD_ANALYTICS_ID;
			const attributes = {
				keyboardShortcut: false,
				// TODO Remove attribute when cleaning up jira_spreadsheet_component_m1
				issueIndex: index !== undefined ? index + 1 : undefined,
			};

			if (isNormalClick(event)) {
				event.preventDefault();
				const analyticsEvent = createAnalyticsEvent({
					action: 'selected',
					actionSubject: 'card',
				});
				fireUIAnalytics(analyticsEvent, id, attributes);
			} else {
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'card',
				});
				fireUIAnalytics(analyticsEvent, id, attributes);
			}
		},
		[createAnalyticsEvent, fragment, index],
	);

	const onContextMenuHandler = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'contextMenu clicked',
			actionSubject: 'card',
		});

		fireUIAnalytics(analyticsEvent, ISSUE_CARD_ANALYTICS_ID, {
			keyboardShortcut: false,
			// TODO Remove attribute when cleaning up jira_spreadsheet_component_m1
			issueIndex: index !== undefined ? index + 1 : undefined,
		});
	}, [createAnalyticsEvent, index]);

	return fragment == null ? (
		<EmptyCard
			ref={cardRef}
			isSelected={isSelected}
			onClick={onClickWithAnalytics}
			{...(fg('nin_serious_accessibility_issues') ? { onFocus } : {})}
		/>
	) : (
		<Card
			ref={cardRef}
			fragment={fragment}
			onClick={onClickWithAnalytics}
			onFocus={onFocus}
			onBlur={onBlur}
			isSelected={isSelected}
			onContextMenu={onContextMenuHandler}
		/>
	);
};

// Exported for DI in tests
export const useFocusedIssueKey = () => useState<IssueKey | null>(null);

const CardList = ({ fragment, loading, onNavigateToIssue }: CardListProps) => {
	markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START);

	// Analytics
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Main
	let focusedIssueIndex = null;
	let setFocusedIssueByIndex = noop;
	if (!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const result = useFocusedIssueIndex();
		focusedIssueIndex = result[0];
		setFocusedIssueByIndex = result[1].setFocusedIssueByIndex;
	}
	const [focusedIssueKey, setFocusedIssueKey] = useFocusedIssueKey();

	const maxIssuesPerPage = getMaxIssuesPerPage();

	const { formatMessage } = useIntl();

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END);
	}, []);

	const fragmentData = useFragment<CardListType>(
		graphql`
			fragment main_issueNavigator_CardList_fragment on JiraIssueConnection {
				edges {
					node {
						key
						...main_issueNavigator_Card_fragment
						...main_issueNavigator_CardWithSelectedIssue_fragment
					}
				}
			}
		`,
		fragment,
	);

	const onIssueChangeEvent = useCallback(
		// TODO Remove issueIndex argument when cleaning up jira_spreadsheet_component_m1
		(issueKey: IssueKey, issueIndex?: number | null) => {
			if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
				if (issueKey) {
					setFocusedIssueKey(issueKey);
				}
			} else if (issueIndex !== null) {
				setFocusedIssueByIndex(issueIndex);
			}
			const id = issueKey ? ISSUE_CARD_ANALYTICS_ID : BLANK_CARD_ANALYTICS_ID;

			const analyticsEvent = createAnalyticsEvent({
				action: 'selected',
				actionSubject: 'card',
			});

			fireUIAnalytics(analyticsEvent, id, {
				keyboardShortcut: true,
				issueIndex: issueIndex == null ? null : issueIndex + 1,
			});
		},
		[createAnalyticsEvent, setFocusedIssueByIndex, setFocusedIssueKey],
	);

	if (loading) {
		if (fg('nin_serious_accessibility_issues')) {
			return (
				<StyledList aria-label={formatMessage(messages.issues)}>
					{[...Array(maxIssuesPerPage)].map<ReactNode>((__, index) => (
						<CardSkeleton key={index} issueIndex={index} issueCount={maxIssuesPerPage} />
					))}
				</StyledList>
			);
		}
		return (
			<>
				{[...Array(maxIssuesPerPage)].map<ReactNode>((__, index) => (
					<CardSkeleton key={index} issueIndex={index} issueCount={maxIssuesPerPage} />
				))}
			</>
		);
	}

	let cards;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		cards = useMemo(
			() =>
				fragmentData?.edges
					?.map((edge) => edge?.node)
					?.filter(Boolean)
					.map((node) => (
						<CardWithSelectedIssue
							key={node.key}
							isFocused={node.key === focusedIssueKey}
							fragment={node}
							onFocus={setFocusedIssueKey}
							onBlur={() => setFocusedIssueKey(null)}
						/>
					)) ?? [],
			[fragmentData?.edges, focusedIssueKey, setFocusedIssueKey],
		);
	} else {
		cards =
			fragmentData?.edges
				?.filter(isNonNullish)
				.map((edge, index: number) => (
					<CardWithSelectedIssueStateOld
						key={index}
						index={index}
						fragment={edge.node ?? undefined}
					/>
				)) ?? [];
	}

	if (cards.length === 0) {
		return <CardEmptyList />;
	}

	return (
		<>
			<KeyboardShortcuts
				focusedIssueIndex={focusedIssueIndex}
				focusedIssueKey={focusedIssueKey}
				onIssueChangeEvent={onIssueChangeEvent}
				onNavigateToIssue={onNavigateToIssue}
				disableListControls={loading}
			/>
			<StyledList
				aria-label={formatMessage(messages.issues)}
				{...(fg('nin_serious_accessibility_issues') ? {} : { role: 'listbox' })}
			>
				{cards}
			</StyledList>
		</>
	);
};

export default CardList;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledList = styled.ul({
	listStyle: 'none',
	paddingLeft: '0px',
});
