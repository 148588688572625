import React, { type PropsWithChildren, useMemo } from 'react';
import { graphql, type RefetchFn, useFragment, useRelayEnvironment } from 'react-relay';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { IssueNavigatorIssueSearchRefetchQuery } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type { issueSearch_issueNavigator$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/issueSearch_issueNavigator.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { DEFAULT_VIEW_ID } from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types';
import { parseIssueNavigatorViewIdOrDefault } from '../../common/utils';
import { useActiveJql } from '../../services/active-jql';
import { IssueSearchServiceContainer } from '../../services/issue-search';
import type { InfiniteScrollProps } from '../../services/issue-search/types.tsx';
import { useSelectedViewMutation } from '../../services/selected-view';

type Props = PropsWithChildren<
	InfiniteScrollProps & {
		/**
		 * Event emitted when page data has loaded and the key experience is interactive.
		 */
		onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
		/**
		 * Fragment ref of the issue search view currently rendered.
		 */
		view: ViewFragment | null;
		/**
		 * Relay refetch function to perform a new issue search.
		 */
		refetch: RefetchFn<IssueNavigatorIssueSearchRefetchQuery>;
	}
>;

export const IssueSearch = ({
	children,
	onPageDataLoad,
	view,
	refetch,
	onLoadNext,
	onLoadPrevious,
	isLoadingNext,
	isLoadingPrevious,
	hasNext,
	hasPrevious,
}: Props) => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const { jql, filterId } = useActiveJql();
	const issueSearchInput = useMemo(
		() => (jql === undefined && isFilterId(filterId) ? { filterId } : { jql }),
		[filterId, jql],
	);
	const onSelectedViewMutation = useSelectedViewMutation();

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment issueSearch_issueNavigator on JiraIssueSearchView {
				viewId @required(action: THROW)
			}
		`,
		view,
	);

	return (
		<IssueSearchServiceContainer
			cloudId={cloudId}
			environment={environment}
			issueSearchInput={issueSearchInput}
			viewId={parseIssueNavigatorViewIdOrDefault(viewData?.viewId, DEFAULT_VIEW_ID)}
			onPageDataLoad={onPageDataLoad}
			onSelectedViewMutation={onSelectedViewMutation}
			refetch={refetch}
			onLoadNext={onLoadNext}
			onLoadPrevious={onLoadPrevious}
			isLoadingNext={isLoadingNext}
			isLoadingPrevious={isLoadingPrevious}
			hasNext={hasNext}
			hasPrevious={hasPrevious}
		>
			{children}
		</IssueSearchServiceContainer>
	);
};
