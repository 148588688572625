import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import ErrorImage from '@atlaskit/icon/glyph/error';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { CardBaseStyle } from '../../../../../common/ui/styled.tsx';
import ErrorInfo from './error-info';
import messages from './messages';
import {
	SkeletonContainer,
	SkeletonBackground,
	ColumnMask,
	SummaryMask,
	FooterMask,
	TextLineMask,
	TypeMask,
	IssueKeyMask,
	SeparatorMask,
	BlankMask,
	Wrapper,
	ErrorIconContainer,
	ErrorWrapper,
	errorWrapperSelector,
	skeletonBackgroundSelector,
	columnMaskSelector,
} from './styled';

type Props = {
	issueIndex: number;
	issueCount: number;
	hasError?: boolean;
};

const BoxFG = ({
	children,
	ariaLabel,
	isFirstCard,
	isLastCard,
	hasError,
}: {
	children: ReactNode;
	ariaLabel: string;
	isFirstCard: boolean;
	isLastCard: boolean;
	hasError: boolean;
}) =>
	fg('nin_serious_accessibility_issues') ? (
		<Box as="li" xcss={ListItemStyle} aria-label={ariaLabel}>
			<CardContainer>{children}</CardContainer>
		</Box>
	) : (
		<Wrapper
			role="option"
			aria-selected={false}
			aria-label={ariaLabel}
			isFirstCard={isFirstCard}
			isLastCard={isLastCard}
			hasError={hasError}
		>
			{children}
		</Wrapper>
	);

const IssueCardSkeleton = ({ hasError = false, issueIndex, issueCount }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<BoxFG
			ariaLabel={formatMessage(messages.issueLoadingLabel, { number: issueIndex })}
			isFirstCard={issueIndex === 0}
			isLastCard={issueIndex + 1 === issueCount}
			hasError={hasError}
		>
			<SkeletonContainer>
				<SkeletonBackground
					hasError={hasError}
					data-component-selector={skeletonBackgroundSelector}
				/>
				<ColumnMask data-component-selector={columnMaskSelector}>
					<SummaryMask>
						<TextLineMask fontSize={20} lineHeight={24} intrusion={20} />
						<TextLineMask fontSize={20} lineHeight={24} intrusion={60} />
					</SummaryMask>
					<FooterMask>
						<TypeMask />
						<SeparatorMask />
						<IssueKeyMask />
						{hasError ? (
							<ErrorIconContainer>
								<ErrorImage primaryColor={token('color.icon.danger', colors.R400)} label="" />
							</ErrorIconContainer>
						) : (
							<BlankMask />
						)}
					</FooterMask>
				</ColumnMask>
				<ErrorWrapper data-component-selector={errorWrapperSelector}>
					<ErrorInfo />
				</ErrorWrapper>
			</SkeletonContainer>
		</BoxFG>
	);
};

export default IssueCardSkeleton;

const ListItemStyle = xcss({
	marginBlockStart: 'space.0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CardContainer = styled(CardBaseStyle)({});
