import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { RECENTLY_VIEWED_URL } from './constants';

export const addFilterToRecent = async (filterId: string, analyticsEvent: UIAnalyticsEvent) => {
	try {
		await performPostRequest(RECENTLY_VIEWED_URL + filterId);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			event: analyticsEvent,
			meta: { id: 'addFilterToRecent', packageName: 'jiraIssueNavigatorCustomFilters' },
		});
	}
};
