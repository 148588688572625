import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ownedBy: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.owner.owned-by',
		defaultMessage: 'Owned by {linkStart}{ownerName}{linkEnd}',
		description: 'Sentence describing which user owns the filter',
	},
	owner: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.owner.owner',
		defaultMessage: 'Owned by <anchor>{ownerName}</anchor>',
		description:
			'Sentence describing which user owns the filter. The name of the user is wrapped in a link component, which will open the users profile page on click',
	},
});
