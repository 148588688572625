import React, { type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { selectedView_issueNavigator_SelectedViewContainer$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/selectedView_issueNavigator_SelectedViewContainer.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { DEFAULT_VIEW_ID } from '../../common/constants';
import type { IssueNavigatorViewId } from '../../common/types';
import { parseIssueNavigatorViewIdOrDefault } from '../../common/utils';
import { Container as SelectedViewStateContainer } from '../../controllers/selected-view-state';
import { useIssueSearchQuery } from '../../services/issue-search-query';

export type Props = {
	children: ReactNode;
	fragment: ViewFragment | null;
	/**
	 * Event emitted when the selected view is updated. The caller may want to specify the issue which should be
	 * selected by default when the view is changed, in which case they can provide an `issueKey` argument.
	 *
	 * @param view View that was updated
	 * @param issueKey Optional issue key that should be selected
	 */
	onSetView: (view: IssueNavigatorViewId, issueKey?: IssueKey) => void;
};

export const SelectedViewContainer = ({ children, onSetView, fragment }: Props) => {
	const { onIssueSearchForView } = useIssueSearchQuery();
	const data = useFragment<ViewFragment>(
		graphql`
			fragment selectedView_issueNavigator_SelectedViewContainer on JiraIssueSearchView {
				viewId
			}
		`,
		fragment,
	);

	const view = parseIssueNavigatorViewIdOrDefault(data?.viewId, DEFAULT_VIEW_ID);

	return (
		<SelectedViewStateContainer
			view={view}
			onSetView={onSetView}
			onIssueSearchForView={onIssueSearchForView}
		>
			{children}
		</SelectedViewStateContainer>
	);
};
