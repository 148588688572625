import React, {
	useCallback,
	useEffect,
	createContext,
	useContext,
	type ReactNode,
	type ReactElement,
} from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useFlagsService } from '@atlassian/jira-flags';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button';
import { FALLBACK_SUPPORT_ARTICLE, LEARN_MORE_IPH_ARTICLE_ID } from '../../common/constants';
import { AsyncSpotlightCard } from '../../common/ui/spotlight';
import type { SpotlightCardProps } from '../../common/ui/spotlight/spotlight-card';
import type { SpotlightTargetProps } from '../../common/ui/spotlight/spotlight-target';
import SpotlightTarget from '../../common/ui/spotlight/spotlight-target/index.tsx';
import messages from './messages';

export const NinChangeboardingContext = createContext(false);

export const EnableNinChangeboardingProvider = ({ children }: { children: ReactNode }) => (
	<NinChangeboardingContext.Provider value>{children}</NinChangeboardingContext.Provider>
);

export const NinChangeboardingTourTarget = ({ children, engagementId }: SpotlightTargetProps) => {
	const isTourEnabled = useContext(NinChangeboardingContext);
	if (!ff('nin.global-scope_aqec8') || !isTourEnabled) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return children as ReactElement;
	}
	return <SpotlightTarget engagementId={engagementId}>{children}</SpotlightTarget>;
};

export const NinChangeboardingTourCard = ({
	mode,
	view,
	hasIssueResults,
	isAiEnabled,
}: SpotlightCardProps) => {
	const isTourEnabled = useContext(NinChangeboardingContext);

	if (!ff('nin.global-scope_aqec8') || !isTourEnabled) {
		return null;
	}
	return (
		<AsyncSpotlightCard
			mode={mode}
			view={view}
			hasIssueResults={hasIssueResults}
			isAiEnabled={isAiEnabled}
		/>
	);
};

export const useGlobalScopeOptInFlag = () => {
	const isFlagEnabled = useContext(NinChangeboardingContext);
	const { showFlag } = useFlagsService();
	const onOpenInProductHelpArticle = useHelpPanelLink();

	const onLearnMore = useCallback(() => {
		if (onOpenInProductHelpArticle !== undefined) {
			onOpenInProductHelpArticle?.(LEARN_MORE_IPH_ARTICLE_ID);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(FALLBACK_SUPPORT_ARTICLE);
		}
	}, [onOpenInProductHelpArticle]);

	useEffect(() => {
		const shouldShowOptInFlag = sessionStorage.getItem('showNinOptInFlag');
		if (ff('nin.global-scope_aqec8') && isFlagEnabled && shouldShowOptInFlag === 'true') {
			showFlag({
				type: 'success',
				title: messages.successOptInTitle,
				description: messages.successOptInDesc,
				actions: [
					{
						content: messages.successActionLearnMoreLink,
						onClick: onLearnMore,
					},
				],
				isAutoDismiss: false,
				testId: 'issue-navigator-changeboarding.optin-success-flag',
			});
			sessionStorage.removeItem('showNinOptInFlag');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
