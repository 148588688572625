import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import TotalIssueCount, { type Props } from './main';

/**
 * Display the total number of issues from a search with the ability to toggle from stable to unstable values.
 *
 * Stable vs Unstable
 *  - the **stable count** is returned from the issueSearchStable query. It will be capped at 1000 if
 *    there are more than 1000 results. This is the default count that is displayed
 *
 *  - the **unstable count** is returned from the totalIssueCountQuery query. It is the true number of results
 *    for a search and does not have a cap. This value must be requested by the user via button click.
 */
const TotalIssueCountWrapper = (props: Props) => {
	const { formatMessage } = useIntl();

	return (
		<JSErrorBoundary
			id="TotalIssueCount"
			packageName="jiraIssueNavigator"
			render={() => (
				<>
					{formatMessage(props.prependMessage, {
						...props.prependMessageValues,
						totalNumberIssues: props.isCapped ? `${props.stableCount}+` : props.stableCount,
					})}
				</>
			)}
			sendToPrivacyUnsafeSplunk
			teamName="empanada"
		>
			<TotalIssueCount {...props} />
		</JSErrorBoundary>
	);
};

export default TotalIssueCountWrapper;
