import React, { type ComponentType, type FC, useState } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Button from '@atlaskit/button';
import DropdownMenu from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ErrorState } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error/index.tsx';
import type { SearchResultPageData } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import Placeholder from '@atlassian/jira-placeholder';

type MeatballMenuProps = {
	jql: string;
	filterId?: string;
	searchResultPageData: SearchResultPageData;
	additionalMenuItems?: React.ReactNode;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const MeatballMenu: ComponentType<MeatballMenuProps> = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-issue-navigator-actions-meatball-menu" */ './index'),
	{ ssr: false },
);

const Fallback = ({ onRetry }: { onRetry: () => void }) => (
	<DropdownMenu
		trigger={({ triggerRef, ...props }) => (
			<Button
				{...props}
				appearance="subtle"
				spacing="default"
				iconBefore={<MoreIcon label="" size="medium" />}
				ref={triggerRef}
			/>
		)}
		shouldFlip
		placement="bottom-end"
		spacing="compact"
	>
		<ErrorState onRetry={onRetry} pageAction="meatballMenu" />
	</DropdownMenu>
);

const AsyncMeatballMenu: FC<MeatballMenuProps> = ({
	jql,
	filterId,
	searchResultPageData,
	additionalMenuItems,
}: MeatballMenuProps) => {
	const [resetCaughtError, setResetCaughtError] = useState<Error>();
	return (
		<JSErrorBoundary
			packageName="jiraIssueNavigatorActionMeatballMenu"
			id="async-issue-navigator-actions-meatball-menu"
			sendToPrivacyUnsafeSplunk
			// Attempt to re-render children when retry is pressed in the fallback error state
			fallback={({ error }) => <Fallback onRetry={() => setResetCaughtError(error)} />}
			onError={() => setResetCaughtError(undefined)}
			resetCaughtError={resetCaughtError}
		>
			<Placeholder
				name="async-issue-navigator-actions-meatball-menu"
				fallback={
					<Button
						appearance="subtle"
						spacing="default"
						isDisabled
						iconBefore={<MoreIcon label="" size="medium" />}
					/>
				}
			>
				<MeatballMenu
					jql={jql}
					filterId={filterId}
					searchResultPageData={searchResultPageData}
					additionalMenuItems={additionalMenuItems}
				/>
			</Placeholder>
		</JSErrorBoundary>
	);
};
export default AsyncMeatballMenu;
