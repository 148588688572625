import React, { forwardRef, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import type { OptionProps } from './types';

export default forwardRef<HTMLParagraphElement, OptionProps>(
	({ name, value, isChecked, isDisabled, onChange, children, testId }, ref) => {
		const id = `${name}-${value}`;
		return (
			<Container ref={ref}>
				<input
					type="radio"
					id={id}
					name={name}
					value={value}
					checked={isChecked}
					disabled={isDisabled}
					data-testid={testId}
					onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(value, event)}
				/>
				<label htmlFor={id}>{children}</label>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.p({
	/*
	 * The input is important for screen-readers, but does not need to be
	 * visually rendered
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	input: visuallyHiddenStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	label: {
		display: 'block',
		padding: `${token('space.050', '4px')} ${token('space.075', '6px')}`,
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'input + label:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'input:checked + label, input:disabled + label': {
		cursor: 'default',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'input:disabled + label': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.disabled', colors.N60),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'input:checked + label:hover, input:disabled + label:hover': {
		backgroundColor: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'input:focus + label': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0px 0px 0px 2px ${token('color.border.focused', colors.B200)}`,
	},
	/*
	 * For browsers that support :focus-visible, we remove the focus-ring when
	 * the focus-ring would not natively display in the browser (e.g. in Chrome
	 * the :focus psuedo-class is applied when clicking on the radio button
	 * with a mouse, but :focus-visible is not)
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'input:focus:not(:focus-visible) + label': {
		boxShadow: 'none',
	},
});
