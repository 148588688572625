import ReactDOM from 'react-dom';
import { createOperationDescriptor, getRequest, fetchQuery } from 'relay-runtime';
import { fg } from '@atlassian/jira-feature-gating';
import RefetchQuery, {
	type IssueNavigatorIssueSearchRefetchQuery,
	type IssueNavigatorIssueSearchRefetchQuery$variables as IssueNavigatorQueryVariables,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import { MAX_AMOUNT_OF_COLUMNS, MAX_ISSUES_PER_PAGE } from '../../common/constants.tsx';
import type { Actions } from './types.tsx';

export const actions: Actions = {
	getInfiniteScrollProps:
		() =>
		(
			_,
			{ onLoadNext, onLoadPrevious, isLoadingNext, isLoadingPrevious, hasNext, hasPrevious },
		) => ({
			onLoadNext,
			onLoadPrevious,
			isLoadingNext,
			isLoadingPrevious,
			hasNext,
			hasPrevious,
		}),
	onChangeView:
		(viewId) =>
		({ dispatch }, { onSelectedViewMutation }) => {
			onSelectedViewMutation(viewId);
			dispatch(
				actions.onRefetch({
					viewId,
				}),
			);
		},
	onRefetch:
		(variables = {}, options = {}) =>
		({ getState, setState }, { cloudId, environment, issueSearchInput, refetch, viewId }) => {
			let { inFlightRequest } = getState();

			// Some consumers may fire refetch queries before in-flight query
			// has been completed (e.g. while user is toggling columns on and off in
			// the column picker). In these situations, we always want to fetch latest
			// data from the API as column configuration may have changed for the user.
			// To do that, we disable Relay's automatic de-deduping of requests.
			inFlightRequest?.unsubscribe();

			const finalVariables: IssueNavigatorQueryVariables = {
				cloudId,
				issueSearchInput,
				first: MAX_ISSUES_PER_PAGE,
				after: null,
				namespace: 'ISSUE_NAVIGATOR',
				viewId,
				options: null,
				fieldSetIds: [],
				shouldQueryFieldSetsById: false,
				amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
				filterId: null,
				queryStatusForIssuekeyStrikethrough: fg(
					'jiv-18659-query-status-for-issuekey-strikethrough',
				),
				...variables,
			};

			inFlightRequest = fetchQuery<IssueNavigatorIssueSearchRefetchQuery>(
				environment,
				RefetchQuery,
				finalVariables,
				{
					fetchPolicy: 'network-only',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				complete: () => {
					/*
					 * fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
					 * data will remain saved in the Relay store at any point after the request completes.
					 * We need to explicitly retain the operation to ensure it doesn't get deleted.
					 * See https://relay.dev/docs/api-reference/fetch-query/#behavior
					 */
					const operation = createOperationDescriptor(getRequest(RefetchQuery), finalVariables);
					const { disposables } = getState();
					setState({
						disposables: disposables.concat([environment.retain(operation)]),
					});

					let hasStoreRefetchCompleted = false;
					// We need to batch these updates as without it, Relay will cause multiple renders and invoke the
					// onComplete callback multiple times. This causes our success/fail analytics events to become
					// unreliable.
					// We introduced a similar fix for fetchQuery (see src/packages/platform/graphql/relay-scheduler/src/index.js)
					// and in React 18 will be able to remove this in favour of the startTransition/useTransition APIs.
					ReactDOM.unstable_batchedUpdates(() => {
						refetch(finalVariables, {
							fetchPolicy: 'store-only',
							onComplete: () => {
								// Relay will dispose the cached operation after a 5m timeout, in which case it will
								// refetch the query from the store and retrigger the onComplete callback. We need to
								// ensure this callback is invoked strictly once to prevent unexpected side effects,
								// e.g. https://jira.atlassian.com/browse/JRACLOUD-84106.
								if (hasStoreRefetchCompleted) {
									return;
								}

								hasStoreRefetchCompleted = true;
								setState({
									isFetching: false,
								});

								options.onComplete?.();
								// TODO JSC-175 Port search key reset logic
								// TODO JSC-176 Port total issue count reset logic
							},
						});
					});
				},
				error: () => {
					setState({
						isFetching: false,
						networkError: true,
					});
					options.onError?.();
					// TODO JSC-174 Port over error handling
				},
				unsubscribe: () => {
					options.onUnsubscribe?.();
				},
			});

			setState({
				inFlightRequest,
				isFetching: true,
				networkError: false,
			});
		},
};
