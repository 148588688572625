import React, { useMemo, useCallback, useState } from 'react';
import { AnalyticsListener, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts';
import { useIntl } from '@atlassian/jira-intl';
import { FilterButton } from '@atlassian/jira-issue-navigator-actions-common/src/ui/filter-button/index.tsx';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import JiraShareButton, {
	type RenderCustomTriggerButton,
	type CustomTriggerButtonProps,
} from '@atlassian/jira-share-button';
import { useSubProduct } from '@atlassian/jira-subproduct';
import ThirdPartyNudge from '@atlassian/jira-third-party-nudge/src/ui/async.tsx';
import { INTEGRATION_TYPES } from '../common/constants';
import { shareSearchClientConfig } from '../common/utils/share-search-client';
import { getShareSubProductId } from '../common/utils/sub-product';
import { messages } from './messages';

const getShareLink = (param: string, value: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { hostname, protocol } = window.location;
	return `${protocol}//${hostname}/issues/?${param}=${encodeURIComponent(value)}`;
};

type Props = {
	jql: string;
	filterId?: string;
	isModifiedFilter?: boolean;
};

const CustomTriggerButton: RenderCustomTriggerButton = (
	{ isDisabled, isSelected, onClick }: CustomTriggerButtonProps,
	triggerProps,
) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onKeyboardShortcutClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'pressed',
				actionSubject: 'keyboardShortcut',
			}),
			'shareSearchCriteriaModalKeyboardShortcut',
			{
				keyPressed: 's',
				keyboardShortcut: true,
			},
		);
		onClick();
	}, [createAnalyticsEvent, onClick]);

	const shortcutsKeyMap = useMemo(
		() => ({
			s: {
				callback: onKeyboardShortcutClick,
				label: <div>{formatMessage(messages.shareKeyboardShortcut)}</div>,
			},
		}),
		[formatMessage, onKeyboardShortcutClick],
	);

	return (
		<>
			<Shortcuts keyMap={shortcutsKeyMap} />
			<FilterButton
				isDisabled={isDisabled}
				isSelected={isSelected}
				onClick={onClick}
				label=""
				text={formatMessage(messages.share)}
				customTriggerProps={{ ...triggerProps, 'aria-haspopup': false }}
			/>
		</>
	);
};

const addContextToEvent = (event: UIAnalyticsEvent) => {
	const attributes = event.context.map((context) => context.attributes);
	event.update((payload: UIAnalyticsEvent) => ({
		...payload,
		attributes: Object.assign(event.payload.attributes || {}, ...attributes),
	}));
};

export const ShareButton = ({ jql, filterId, isModifiedFilter = false }: Props) => {
	const { formatMessage } = useIntl();
	const subProduct = useSubProduct();

	const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = useState(false);

	const showFilterShareLinkNew =
		filterId !== undefined && isCustomFilter(filterId) && !isModifiedFilter;

	// share custom filters by ID if the JQL is not overridden, otherwise just share the JQL
	const additionalProps = showFilterShareLinkNew
		? {
				integrationType: INTEGRATION_TYPES.FILTER,
				shareContentType: INTEGRATION_TYPES.FILTER,
				shareLink: getShareLink('filter', filterId),
				shareTitle: formatMessage(messages.filter),
				shareFormTitle: formatMessage(messages.dialogTitleFilter),
			}
		: {
				integrationType: INTEGRATION_TYPES.SEARCH,
				shareContentType: INTEGRATION_TYPES.SEARCH,
				shareLink: getShareLink('jql', jql),
				shareTitle: formatMessage(messages.search),
				shareFormTitle: formatMessage(messages.dialogTitleSearch),
			};

	return (
		<AnalyticsListener channel="*" onEvent={addContextToEvent}>
			{shouldShowThirdPartyNudge && <ThirdPartyNudge />}
			<JiraShareButton
				triggerButtonStyle="icon-with-text"
				productId="jira"
				subProductId={getShareSubProductId(subProduct)}
				objectAri="" // The legacy sharing API does not use ARIs
				legacyShareClientConfig={shareSearchClientConfig}
				renderCustomTriggerButton={CustomTriggerButton}
				onTriggerButtonClick={() => {
					setShouldShowThirdPartyNudge(true);
				}}
				{...additionalProps}
			/>
		</AnalyticsListener>
	);
};
