import React, {
	createContext,
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	useLayoutEffect,
} from 'react';
import noop from 'lodash/noop';
import { graphql, type RefetchFn, useFragment } from 'react-relay';
import type { FetchQueryFetchPolicy, GraphQLTaggedNode } from 'relay-runtime';
import {
	isCustomFilter,
	isFilterId,
} from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters';
import {
	usePreviousWithInitial,
	usePrevious,
} from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal';
import {
	ContextualAnalyticsData,
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueNavigatorResultsRefetchQuery as IssueNavigatorRefetchQueryOld } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorResultsRefetchQuery.graphql';
import type { issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults.graphql';
import type { issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { MAX_ISSUES_PER_PAGE, DEFAULT_VIEW_ID } from '../../common/constants';
import type { IssueNavigatorViewId } from '../../common/types';
import {
	convertFilterIdToIssueNavigatorId,
	convertToView,
	isFilterViewId,
	isNonNullish,
	parseIssueNavigatorViewIdOrDefault,
} from '../../common/utils';
import { useActiveJql } from '../active-jql';
import { useExperienceAnalytics } from '../experience-analytics';
import { type RequestEventHandlers, useIssueSearchRefetch } from '../issue-search-refetch';
import {
	type UncappedTotalIssueCount,
	type UncappedTotalIssueCountFetcher,
	uncappedTotalInitialState,
} from '../issue-search-total-count';
import { useSelectedViewMutation } from '../selected-view';
import { useOnDeleteIssue } from './utils';

type IssueSearchRenderProps = {
	/**
	 * This property will be `true` while Relay is fetching data.
	 */
	isFetching: boolean;
	/**
	 * This property will be `true` while Relay is fetching data specifically from a refresh operation.
	 */
	isRefreshing: boolean;
	/**
	 * This property is used to denote if view id has changed from previous request
	 */
	hasViewIdChanged: boolean;
	/**
	 * This property will be `true` when the most recent fetch operation has failed.
	 */
	isNetworkError: boolean;
	/**
	 * This property will be `true` when the user has refreshed a page of issues that has no data but the search result
	 * is not empty, e.g. the user refreshes page 3 but there are only 100 matching issues.
	 */
	isStalePage: boolean;
	/**
	 * Unique key that can be used to identify a new connection of search results.
	 */
	searchKey: string;
	/**
	 * Event emitted when an issue is deleted. This will nullify the issue node in the Relay store which will be shown
	 * as an unavailable issue in the UI.
	 * This will return the issue key of the next or previous issue to select. If there are no next or previous issues
	 * then an empty string will be returned.
	 */
	onDeleteIssue: (issueKeyToDelete: string) => string;

	onFetchUncappedTotalIssueCount: UncappedTotalIssueCountFetcher;
	/**
	 * This will refetch issue and field configuration data from the network using the current JQL input variables.
	 */
	onIssueSearch: () => void;
	/**
	 * This will refetch issue and field configuration data for the provided page cursor.
	 */
	onIssueSearchForPage: (after: string, options?: RequestEventHandlers) => void;
	/**
	 * This will refetch issue and field configuration data for the current page.
	 */
	onIssueSearchForCurrentPage: () => void;
	/**
	 * This will refetch issue and field configuration data for the next page if applicable and return a boolean
	 * indicating if a request was made.
	 */
	onIssueSearchForNextPage: (options?: RequestEventHandlers) => boolean;
	/**
	 * This will refetch issue and field configuration data for the previous page if applicable and return a boolean
	 * indicating if a request was made.
	 */
	onIssueSearchForPreviousPage: (options?: RequestEventHandlers) => boolean;
	/**
	 * This will refetch issue and field configuration data for the provided view.
	 *
	 * @param view View that was updated
	 */
	onIssueSearchForView: (view: IssueNavigatorViewId) => void;
	/**
	 * This will refresh issue and field configuration data for the current page, triggering a new search using
	 * offset based pagination. This does not operate on stable search results.
	 */
	onIssueSearchRefresh: () => void;
	/**
	 * This will refetch issue data of both detail and list view fields for the given issue key.
	 */
	onIssueByFieldsRefetch: (issueKey: string) => void;

	uncappedTotalIssueCount: UncappedTotalIssueCount;
};

export type IssueSearchQueryProviderProps = {
	issueResults: IssueResultsFragment | null;
	view: ViewFragment | null;
	refetch: RefetchFn<IssueNavigatorRefetchQueryOld>;
	query: GraphQLTaggedNode;
	children: ReactNode;
	/**
	 * Event emitted when page data has loaded and the key experience is interactive.
	 */
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
	/**
	 * Event emitted when the page is changed.
	 */
	onChangePage?: () => void;
};

// Maximum number of times we will attempt to backfill our page of issues, e.g. if the user refreshes on page 3 but
// there are only 100 issues then we refresh page 2.
const MAX_BACKFILL_RETRIES = 1;

const IssueSearchQueryContext = createContext<IssueSearchRenderProps>({
	isFetching: false,
	isRefreshing: false,
	hasViewIdChanged: false,
	isNetworkError: false,
	isStalePage: false,
	onDeleteIssue: () => '',
	onFetchUncappedTotalIssueCount: noop,
	onIssueSearch: noop,
	onIssueSearchForPage: noop,
	onIssueSearchForCurrentPage: noop,
	onIssueSearchForNextPage: () => false,
	onIssueSearchForPreviousPage: () => false,
	onIssueSearchForView: noop,
	onIssueSearchRefresh: noop,
	onIssueByFieldsRefetch: noop,
	searchKey: '',
	uncappedTotalIssueCount: { ...uncappedTotalInitialState },
});
IssueSearchQueryContext.displayName = 'IssueSearchQueryContext';

export const useIssueSearchQuery = () => useContext(IssueSearchQueryContext);

export const IssueSearchQueryProvider = ({
	refetch,
	issueResults,
	view,
	query,
	onPageDataLoad,
	onChangePage,
	children,
}: IssueSearchQueryProviderProps) => {
	/* eslint-disable @atlassian/relay/unused-fields, @atlassian/relay/query-restriction */
	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults on JiraIssueConnection {
				__id
				edges {
					__id
					node {
						key
					}
				}
				totalIssueSearchResultCount
				pageCursors(maxCursors: 7) {
					around {
						cursor
						isCurrent
						pageNumber
					}
				}
			}
		`,
		issueResults,
	);

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view on JiraIssueSearchView {
				viewId @required(action: THROW)
				fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED })
					@required(action: THROW) {
					edges @required(action: THROW) {
						node @required(action: THROW) {
							fieldSetId @required(action: THROW)
						}
					}
				}
			}
		`,
		view,
	);
	/* eslint-enable @atlassian/relay/unused-fields, @atlassian/relay/query-restriction */

	const [isRefreshing, setIsRefreshing] = useState(false);
	const { jql, filterId } = useActiveJql();

	// Track if a refetch action is about to be called due to changing issueSearchInput
	const willFetch = useRef<boolean>(false);

	// Track currently selected view as a ref which will be used as an argument for any issue search queries
	const selectedViewId = useRef<IssueNavigatorViewId>(
		parseIssueNavigatorViewIdOrDefault(viewData?.viewId, DEFAULT_VIEW_ID),
	);

	// Only a fixed set of input variables are recomputed for our refetch query.
	const issueSearchInput = useMemo(
		() => (jql === undefined && isFilterId(filterId) ? { filterId } : { jql }),
		[filterId, jql],
	);

	const prevIssueSearchInput = usePreviousWithInitial(issueSearchInput);

	const fieldSetIds = useMemo(
		() =>
			viewData?.fieldSets?.edges
				.filter(isNonNullish)
				.map(({ node: { fieldSetId } }) => fieldSetId) ?? [],
		[viewData?.fieldSets?.edges],
	);

	const { onIssueSearchFail } = useExperienceAnalytics(onPageDataLoad, selectedViewId.current);

	const {
		isFetching,
		hasViewIdChanged,
		isNetworkError,
		onIssueSearchRefetch,
		onIssueByFieldsRefetch,
		searchKey,
		uncappedTotalIssueCount,
		onFetchUncappedTotalIssueCount,
	} = useIssueSearchRefetch(
		refetch,
		query,
		issueSearchInput,
		selectedViewId.current,
		issueResultsData?.__id,
		fieldSetIds,
		onIssueSearchFail,
	);

	const onIssueSearch = useCallback(() => {
		// Execute a new search against the network with current JQL input arguments
		onIssueSearchRefetch({}, { fetchPolicy: 'network-only' });
	}, [onIssueSearchRefetch]);

	const hasIssueSearchInputChanged = !isShallowEqual(issueSearchInput, prevIssueSearchInput);
	if (hasIssueSearchInputChanged) {
		// We track this during render instead of waiting for useEffect to fire so there is no intermediary render for
		// downstream components where `jql` has changed but `isFetching` is still false.
		willFetch.current = true;
	}

	useEffect(() => {
		if (hasIssueSearchInputChanged) {
			willFetch.current = false;
			const shouldLoadWithFilterViewId =
				selectedViewId.current !== 'detail' &&
				issueSearchInput.filterId &&
				isCustomFilter(issueSearchInput.filterId);
			const isCurrentViewIdInvalid =
				isFilterViewId(selectedViewId.current) &&
				selectedViewId.current !== `list_filter_${filterId}`;

			if (issueSearchInput.filterId && shouldLoadWithFilterViewId) {
				selectedViewId.current = convertFilterIdToIssueNavigatorId(issueSearchInput.filterId);
				onIssueSearchRefetch({ viewId: selectedViewId.current }, { fetchPolicy: 'network-only' });
			} else if (isCurrentViewIdInvalid) {
				selectedViewId.current = DEFAULT_VIEW_ID;
				onIssueSearchRefetch({ viewId: selectedViewId.current }, { fetchPolicy: 'network-only' });
			} else {
				onIssueSearch();
			}
		}
	}, [
		filterId,
		hasIssueSearchInputChanged,
		issueSearchInput,
		onIssueSearch,
		onIssueSearchRefetch,
		prevIssueSearchInput,
	]);

	const cursors = issueResultsData?.pageCursors?.around;
	const currentPageIndex = useMemo(
		() =>
			cursors?.findIndex(
				(
					cursor:
						| {
								readonly cursor: string | null | undefined;
								readonly isCurrent: boolean | null | undefined;
								readonly pageNumber: number | null | undefined;
						  }
						| null
						| undefined,
				) => cursor?.isCurrent,
			) ?? -1,
		[cursors],
	);

	const currentPage = currentPageIndex > -1 ? cursors?.[currentPageIndex] : undefined;
	const currentPageCursor = currentPage?.cursor ?? null;
	const currentPageNumber = currentPage?.pageNumber ?? 1;

	// Ignoring linting errors for the conditional call as the FF does not change during runtime
	const onSelectedViewMutation = useSelectedViewMutation();
	const isAnonymous = useIsAnonymous();

	/**
	 * This will refetch issue and field configuration data for the provided view.
	 */
	const onIssueSearchForView = useCallback(
		(newViewId: IssueNavigatorViewId) => {
			// Only allow non-anonymous users to invoke the mutation to update the user preference

			if (!isAnonymous && convertToView(newViewId) !== convertToView(selectedViewId.current)) {
				onSelectedViewMutation(newViewId);
			}
			// Update our viewId according to the selected view and refetch our issue data
			selectedViewId.current = newViewId;
			// If we have no currentPageCursor then we can assume our search has failed. In this case we need to force a
			// network call as otherwise Relay will serve null data from cache.
			const options: { isNewSearchKey: boolean; fetchPolicy?: FetchQueryFetchPolicy } = {
				isNewSearchKey: false,
				fetchPolicy: 'network-only',
			};

			onIssueSearchRefetch({ after: currentPageCursor, viewId: selectedViewId.current }, options);
		},
		[currentPageCursor, isAnonymous, onIssueSearchRefetch, onSelectedViewMutation],
	);

	/**
	 * Event emitted when an issue is deleted.
	 */
	const onDeleteIssue = useOnDeleteIssue(issueResultsData);

	/**
	 * This will refetch issue and field configuration data for the provided page cursor.
	 */
	const onIssueSearchForPage = useCallback(
		(after: string, options?: RequestEventHandlers) => {
			onIssueSearchRefetch({ after }, options);
			onChangePage && onChangePage();
		},
		[onChangePage, onIssueSearchRefetch],
	);

	const onIssueSearchForNextPage = useCallback(
		(options?: RequestEventHandlers) => {
			if (currentPageIndex > -1) {
				const nextPage = cursors?.[currentPageIndex + 1];
				if (nextPage && nextPage.cursor != null) {
					onIssueSearchForPage(nextPage.cursor, options);
					return true;
				}
			}
			return false;
		},
		[currentPageIndex, cursors, onIssueSearchForPage],
	);

	const onIssueSearchForPreviousPage = useCallback(
		(options?: RequestEventHandlers) => {
			if (currentPageIndex > 0) {
				const previousPage = cursors?.[currentPageIndex - 1];
				if (previousPage && previousPage.cursor != null) {
					onIssueSearchForPage(previousPage.cursor, options);
					return true;
				}
			}
			return false;
		},
		[currentPageIndex, cursors, onIssueSearchForPage],
	);

	/**
	 * This will refetch issue and field configuration data for the current page.
	 */
	const onIssueSearchForCurrentPage = useCallback(() => {
		onIssueSearchRefetch(
			{ after: currentPageCursor },
			{ fetchPolicy: 'network-only', isNewSearchKey: false },
		);
	}, [currentPageCursor, onIssueSearchRefetch]);

	/**
	 * This will refresh issue and field configuration data for the provided page number (or current page if no page
	 * number is given), triggering a new search using offset based pagination.
	 */
	const onIssueSearchRefresh = useCallback(
		(pageNumber: number = currentPageNumber) => {
			setIsRefreshing(true);
			// Offset based request - API will run a new (i.e. non-stable) search for the given range
			onIssueSearchRefetch(
				{ first: pageNumber * MAX_ISSUES_PER_PAGE, last: MAX_ISSUES_PER_PAGE },
				{
					fetchPolicy: 'network-only',
					onComplete: () => {
						setIsRefreshing(false);
					},
					onError: () => {
						setIsRefreshing(false);
					},
					onUnsubscribe: () => {
						setIsRefreshing(false);
					},
				},
			);
		},
		[currentPageNumber, onIssueSearchRefetch],
	);

	const backfillRetryCount = useRef(0);

	const edges = issueResultsData?.edges ?? [];
	const total = issueResultsData?.totalIssueSearchResultCount ?? 0;
	const isStalePage = total > 0 && edges.length === 0;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const prevIssueResultsData = usePrevious(issueResultsData);

	/**
	 * Handle the edge case where the user refreshes a page (e.g. page 3) and there are no issues present on the page.
	 * In this scenario we automatically trigger a refresh request for the last available page with issues.
	 */
	useLayoutEffect(() => {
		if (issueResultsData !== prevIssueResultsData) {
			if (isStalePage && backfillRetryCount.current <= MAX_BACKFILL_RETRIES) {
				const pageNumber =
					// If we've reached our limit then fallback to page 1
					backfillRetryCount.current === MAX_BACKFILL_RETRIES
						? 1
						: Math.ceil(total / MAX_ISSUES_PER_PAGE);
				onIssueSearchRefresh(pageNumber);
				backfillRetryCount.current += 1;
				fireOperationalAnalytics(createAnalyticsEvent({}), 'issueSearchResults backfill');
				return;
			}
			backfillRetryCount.current = 0;
		}
	}, [
		createAnalyticsEvent,
		issueResultsData,
		prevIssueResultsData,
		isStalePage,
		onIssueSearchRefresh,
		total,
	]);

	const contextValue: IssueSearchRenderProps = useMemo(
		() => ({
			isFetching: isFetching || willFetch.current,
			isRefreshing,
			hasViewIdChanged,
			isNetworkError,
			isStalePage,
			searchKey,
			onDeleteIssue,
			onIssueSearch,
			onIssueSearchForPage,
			onIssueSearchForCurrentPage,
			onIssueSearchForNextPage,
			onIssueSearchForPreviousPage,
			onIssueSearchForView,
			onIssueSearchRefresh,
			onIssueByFieldsRefetch,
			onFetchUncappedTotalIssueCount,
			uncappedTotalIssueCount,
		}),
		[
			isFetching,
			isRefreshing,
			hasViewIdChanged,
			isNetworkError,
			isStalePage,
			searchKey,
			onDeleteIssue,
			onIssueSearch,
			onIssueSearchForPage,
			onIssueSearchForCurrentPage,
			onIssueSearchForNextPage,
			onIssueSearchForPreviousPage,
			onIssueSearchForView,
			onIssueSearchRefresh,
			onIssueByFieldsRefetch,
			onFetchUncappedTotalIssueCount,
			uncappedTotalIssueCount,
		],
	);

	return (
		<ContextualAnalyticsData attributes={{ issuesLoaded: total }}>
			<IssueSearchQueryContext.Provider value={contextValue}>
				{children}
			</IssueSearchQueryContext.Provider>
		</ContextualAnalyticsData>
	);
};
