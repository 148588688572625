import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ActiveKeyMap } from '../shortcut-registry';

const wrapCallbackWithErrorHandling =
	(
		key: string,
		callback: (e?: KeyboardEvent) => void,
		location = 'keyboard-shortcuts.common',
	): ((e?: KeyboardEvent) => void) =>
	(e) => {
		try {
			callback(e);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				location,
				`Error firing shortcut '${key}': ${error.message} (commandPaletteBlockNativeShortcutFF=true)`,
				error,
			);
		}
	};

export const bindKeyMapWithErrorHandling = (
	keyMap: ActiveKeyMap,
	location?: string,
): ActiveKeyMap =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(keyMap).reduce<Record<string, any>>(
		(map, key) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...map,
			[key]: {
				...keyMap[key],
				callback: wrapCallbackWithErrorHandling(key, keyMap[key].callback, location),
			},
		}),
		{},
	);
