/**
 * @generated SignedSource<<68353f83f54d1e8b8acf18c208378932>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_refetchQueryNew$data = {
  readonly jira: {
    readonly issueSearchViewResult: {
      readonly __typename: "JiraIssueSearchView";
      readonly " $fragmentSpreads": FragmentRefs<"issueSearch_issueNavigator" | "main_issueNavigator_IssueNavigatorUI_viewResult" | "selectedView_issueNavigator_SelectedViewContainer">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_refetchQueryNew";
};
export type main_issueNavigator_IssueNavigator_refetchQueryNew$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_refetchQueryNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew">;
};

import IssueNavigatorIssueSearchRefetchQuery_graphql from './IssueNavigatorIssueSearchRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "options"
    },
    {
      "kind": "RootArgument",
      "name": "queryStatusForIssuekeyStrikethrough"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": IssueNavigatorIssueSearchRefetchQuery_graphql
    }
  },
  "name": "main_issueNavigator_IssueNavigator_refetchQueryNew",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueNavigator_issueSearchData"
    },
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Variable",
              "name": "filterId",
              "variableName": "filterId"
            },
            {
              "kind": "Variable",
              "name": "namespace",
              "variableName": "namespace"
            },
            {
              "kind": "Variable",
              "name": "viewId",
              "variableName": "viewId"
            }
          ],
          "kind": "LinkedField",
          "name": "issueSearchViewResult",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "issueSearch_issueNavigator"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "selectedView_issueNavigator_SelectedViewContainer"
                }
              ],
              "type": "JiraIssueSearchView"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_IssueNavigatorUI_viewResult"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "611c62c80fa368ece022de74701e875a";

export default node;
