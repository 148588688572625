/**
 * @generated SignedSource<<aef77ba13ca109722cbb13f7c1c07d3b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueSearch_issueNavigator$data = {
  readonly viewId: string;
  readonly " $fragmentType": "issueSearch_issueNavigator";
};
export type issueSearch_issueNavigator$key = {
  readonly " $data"?: issueSearch_issueNavigator$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueSearch_issueNavigator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueSearch_issueNavigator",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "viewId"
      },
      "action": "THROW",
      "path": "viewId"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "948ce2677fadfd61c1d24c19e846b034";

export default node;
