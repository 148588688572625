import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { IssueCountProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import PageText from '../../../../common/ui/page-text';
import RefreshButton from '../../../../common/ui/refresh-button';
import { useIssueSearchQuery } from '../../../../services/issue-search-query';

/**
 * Override default issue table issue count to support a refresh button and fetching the 'real' total issue count.
 */
export const IssueCount = (issueCountProps: IssueCountProps) => {
	const { isStalePage } = useIssueSearchQuery();

	return (
		<IssueCountContainer
			data-testid="issue-navigator.ui.issue-results.list-view.issue-count"
			isFlexEnd={issueCountProps.isNewFooterOrderEnabled}
		>
			{/* Do not show issue count while issue data is stale as page information will be inaccurate */}
			{!isStalePage && (
				<span data-testid="issue-navigator.ui.issue-results.list-view.issue-count.text">
					<PageText {...issueCountProps} />
				</span>
			)}
			<RefreshButton />
		</IssueCountContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div<{ isFlexEnd: boolean }>({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	// cleanup isFlexEnd with visual-refresh FG
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	justifyContent: ({ isFlexEnd }) => (isFlexEnd ? 'flex-end' : 'center'),
	fontWeight: 600,
	gap: token('space.050', '4px'),
});
