import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createStore,
	createContainer,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';
import { DEFAULT_VIEW_ID } from '../../common/constants';
import type { IssueNavigatorViewId } from '../../common/types';

type StoreType = {
	view: IssueNavigatorViewId;
};
type PropType = StoreType & {
	/**
	 * Event emitted when the selected view is initialised or updated. The caller may want to specify the issue which
	 * should be selected by default when the view is changed, in which case they can provide an `issueKey` argument.
	 *
	 * @param view View that was set in the store
	 * @param issueKey Optional issue key that should be selected
	 */
	onSetView: (view: IssueNavigatorViewId, issueKey?: IssueKey) => void;
	/**
	 * Event emitted when the selected view is updated.
	 *
	 * @param view View that was updated
	 */
	onIssueSearchForView?: (view: IssueNavigatorViewId) => void;
};

type ActionType = {
	setView: (view: IssueNavigatorViewId, issueKey: IssueKey) => Action<StoreType, PropType>;
};

export const Container = createContainer<PropType>();
const Store = createStore<StoreType, ActionType, PropType>({
	initialState: { view: DEFAULT_VIEW_ID },
	containedBy: Container,
	actions: {
		setView:
			(view, issueKey) =>
			({ setState }, { onSetView, onIssueSearchForView }) => {
				setState({ view });
				onSetView(view, issueKey);
				onIssueSearchForView && onIssueSearchForView(view);
			},
	},
	handlers: {
		onInit:
			() =>
			({ setState }, { view }) => {
				setState({ view });
			},
		onContainerUpdate:
			(nextProps, prevProps) =>
			({ setState }) => {
				if (nextProps.view !== prevProps.view) {
					setState({ view: nextProps.view });
				}
			},
	},
	name: 'IssueNavigatorSelectedViewStateNew',
});

export const useSelectedViewState = createHook(Store);
