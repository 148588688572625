/**
 * An observable that emits key sequences as a string representation
 *
 * Examples:
 *
 * g i
 * shift+enter
 */

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/switchMapTo';
import { toKeyWithModifiers } from './keys';
import type { KeySequence } from './types';

const ignoredKeys = {
	Control: true,
	Meta: true,
	Shift: true,
	Alt: true,
	AltGraph: true,
	CapsLock: true,
	Fn: true,
	FnLock: true,
	Hyper: true,
	NumLock: true,
	OS: true,
	ScrollLock: true,
	Super: true,
	Symbol: true,
	SymbolLock: true,
} as const;

/**
 * Skipping few input types to allow keydown event propagation to it's parent element
 * inputMode - react-select or select or combo
 * type - skipping these inputtypes (checkbox, radio) to support shortcuts like
 *        e(EPIC panel) or v(VERSION panel) or n(board card selection) in board and backlog
 * This condition added as a part of https://a11y.atlassian.net/browse/JCA11Y-1317
 */
const isNonTextInput = ({ inputMode = '', type }: { inputMode: string; type: string }) =>
	inputMode === 'none' || type === 'checkbox';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEditable = (node: any) =>
	(node.nodeName === 'INPUT' && !isNonTextInput(node)) ||
	node.nodeName === 'TEXTAREA' ||
	node.isContentEditable === true;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRelevantKeyEvent = (e: any) =>
	typeof e.key === 'string' &&
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly Control: true; readonly Meta: true; readonly Shift: true; readonly Alt: true; readonly AltGraph: true; readonly CapsLock: true; readonly Fn: true; readonly FnLock: true; readonly Hyper: true; ... 5 more ...; readonly SymbolLock: true; }'.
	(e.key === 'Escape' || (!isEditable(e.target) && !ignoredKeys[e.key]));

/**
 * Finding all `aui-blanket` elements on the page and check if they are all hidden.
 */
export const isLegacyAuiBlanketClosed = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const elBlankets = document.querySelectorAll('.aui-blanket');

	if (elBlankets.length === 0) {
		// no aui-blanket in DOM, so it means it's closed
		return true;
	}

	const isAllHidden = Array.from(elBlankets || []).every((el) => {
		if (
			el.getAttribute('aria-hidden') === 'true' ||
			// when `el.getAttribute('hidden')` returns an empty string, it means the element has the `hidden` attribute and it's invisible.
			el.getAttribute('hidden') !== null
		) {
			return true;
		}

		return false;
	});

	return isAllHidden;
};

let key$: Observable<KeySequence | undefined>;

const flush$ = new Subject();

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	if (!key$) {
		key$ = __SERVER__
			? new Subject()
			: // @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<KeyboardEvent>'.
				Observable.fromEvent(document, 'keydown')
					.filter(isRelevantKeyEvent)
					.filter(isLegacyAuiBlanketClosed)
					.map(toKeyWithModifiers);

		const sequence$: Observable<KeySequence | undefined> = key$.scan((seq, key) => {
			if (!key || !seq) return undefined;

			return {
				sequence: `${String(seq.sequence)} ${String(key.sequence)}`,
				e: key.e,
			};
		});
		key$ = key$.debounceTime(1000).startWith(null).merge(flush$).switchMapTo(sequence$);
	}
	return key$;
};

export const flush = () => {
	flush$.next();
};
