import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fromFlagId,
	type FlagId,
	useFlagsService,
	type CommonFlagAction,
} from '@atlassian/jira-flags';
import JiraSuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	id: FlagId;
	filterName: string;
	viewUrl: string;
	analyticsEvent: UIAnalyticsEvent;
};

export const SuccessFlag = ({ id, filterName, viewUrl, analyticsEvent }: Props) => {
	const { formatMessage } = useIntl();
	const { dismissFlag } = useFlagsService();
	const [isCopied, setCopied] = useState(false);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isCopied) {
			timeoutId = setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
		return () => {
			timeoutId && clearTimeout(timeoutId);
		};
	}, [isCopied]);

	const copyToClipboard = useCallback((): void => {
		try {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			navigator.clipboard.writeText(viewUrl);
			setCopied(true);
			fireUIAnalytics(analyticsEvent, 'copyLink');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'copyToClipboard',
					packageName: 'jiraIssueNavigatorActionsCommon',
				},
			});
		}
	}, [analyticsEvent, viewUrl]);

	const actions: CommonFlagAction[] = useMemo(() => {
		const flagActions: Array<CommonFlagAction> = [];

		flagActions.push({
			content: isCopied ? (
				<CopySuccess>{formatMessage(messages.copyActionCopied)}</CopySuccess>
			) : (
				formatMessage(messages.copyAction)
			),
			onClick: copyToClipboard,
		});

		return flagActions;
	}, [copyToClipboard, formatMessage, isCopied]);

	const onDismissed = useCallback(() => dismissFlag(id), [dismissFlag, id]);

	return (
		<JiraSuccessFlag
			id={fromFlagId(id)}
			onDismissed={onDismissed}
			title={
				<WordBreakSpan>{formatMessage(messages.flagTitle, { name: filterName })}</WordBreakSpan>
			}
			description={formatMessage(messages.flagDescription)}
			actions={actions}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CopySuccess = styled.span({
	color: `${token('color.text.success', G300)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WordBreakSpan = styled.span({
	wordBreak: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	WordBreakSpan,
};
