import { useCallback } from 'react';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters';
import { useQueryParam } from '@atlassian/react-resource-router';
import { FilterTypes, SearchInputTypes } from '../constants';
import type { FilterJql, SearchInputType, FilterId } from '../types';

type JqlQueryParamCallback = (jql: string) => void;

export const getJqlFromParamValues = (
	defaultJql: string,
	jqlParam: string | undefined,
	filter: string | undefined,
	/**
	 * when `filter` has a value that is not a standard numeric filter ID, consumers can provide a
	 * customMapper function to transform the filter value to a jql string. For example this is used
	 * by Project Issue Navigator to map filter query params such as '?filter=myopenissues into jql
	 * strings without calling the backend API
	 */
	customMapper?: (filter: string) => string | undefined,
	/**
	 * callback in the event of jql being returned is different to the jqlParam value, such as when
	 * it is undefined and the default jql is being returned. This allows the caller to sync the new
	 * jql value with the `?jql=...` url query param
	 */
	onMutateJqlParam?: JqlQueryParamCallback,
): string | undefined => {
	let jql = jqlParam;

	if (typeof jql === 'string') {
		return jql;
	}

	if (isFilterId(filter)) {
		return undefined;
	}

	if (typeof filter === 'string' && customMapper) {
		jql = customMapper(filter);
	}

	if (jql === undefined) {
		jql = defaultJql;
	}

	if (jql !== jqlParam) {
		onMutateJqlParam?.(jql);
	}

	return jql;
};

const getFilter = (filterInput: string): FilterJql | FilterId =>
	isFilterId(filterInput)
		? { value: filterInput, type: FilterTypes.ID }
		: { value: filterInput, type: FilterTypes.JQL };

export const getSearchInput = (
	jqlInput: string | undefined,
	filterInput: string | undefined,
): SearchInputType => {
	const filter = filterInput !== undefined ? getFilter(filterInput) : undefined;
	if (jqlInput === undefined) {
		if (filter === undefined) {
			throw new Error('filter is missing for search input');
		}
		return filter;
	}
	if (filter === undefined) {
		return { jql: jqlInput, type: SearchInputTypes.JQL };
	}
	return { jql: jqlInput, filter, type: SearchInputTypes.FILTER_AND_JQL };
};

export const useUpdateUrlQueryParamCallback = (queryParamKey: string) => {
	const [_, setQueryParam] = useQueryParam(queryParamKey);

	const setJqlQueryParamCallback = useCallback<JqlQueryParamCallback>(
		(value) => setQueryParam(value, 'replace'),
		[setQueryParam],
	);

	return setJqlQueryParamCallback;
};
