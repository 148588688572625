import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { ExternalError, ExternalMessage } from '@atlaskit/jql-editor';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SaveFilterDropdown } from '@atlassian/jira-issue-navigator-custom-filters/src/ui/save-filter-dropdown/main.tsx';
import { ShortcutsDialogIntegrationProvider } from '@atlassian/jira-jql-builder-basic/src/controllers/dot-dialog-context/index.tsx';
import { JQLBuilderErrorBoundryFallback } from '@atlassian/jira-jql-builder-common/src/ui/error-boundry-fallback/index.tsx';
import JQLBuilder from '@atlassian/jira-jql-builder-with-ai/src/async.tsx';
import {
	SEARCH_MODE_BASIC,
	SEARCH_MODE_ADVANCED,
} from '@atlassian/jira-jql-builder/src/common/constants.tsx';
import type { SearchMode, JQLBuilderProps } from '@atlassian/jira-jql-builder/src/common/types.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { jqlBuilder_issueNavigator_JQLBuilderWrapper_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/jqlBuilder_issueNavigator_JQLBuilderWrapper_filter.graphql';
import type { jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults.graphql';
import type { jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey$key as JqlBuilderWithAiKey } from '@atlassian/jira-relay/src/__generated__/jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey.graphql';
import { AsyncSaveFilterDialog as SaveFilterDialog } from '@atlassian/jira-save-filter-dialog/src/ui/save-filter-dialog/async.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { ANALYTICS_SOURCE, PACKAGE_NAME, TEAM_NAME } from '../../common/constants';
import { isNonNullish } from '../../common/utils';
import { marks } from '../../common/utils/performance-analytics';
import { useAppContextualAnalyticsActions } from '../../controllers/app-contextual-analytics';
import { useJqlSearch } from '../../controllers/jql-search';
import { useJqlSearchStatus } from '../../controllers/jql-search-status';
import { useActiveJql } from '../../services/active-jql';
import { useIssueSearchQuery } from '../../services/issue-search-query';
import messages from './messages';
import { NoOptionsMessage } from './no-options-message';
import ResetButton from './reset-button';
import { useJqlBuilderInstrumentation } from './use-jql-builder-instrumentation';
import {
	isOnlyJqlAndDifferentToDefault,
	filterAndJqlIsTheSameAndDifferentToDefault,
	isFilterJqlModified,
	isOnlyFilterAndDifferentToDefault,
	jqlIsDifferentToFilter,
} from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useJqlBuilderInstrumentation } from './use-jql-builder-instrumentation';

export type OverridableJqlBuilderProps = Pick<
	JQLBuilderProps,
	| 'projectOptions'
	| 'singleSelectFields'
	| 'renderExtraControls'
	| 'searchModes'
	| 'NoOptionComponent'
>;

export type Props = {
	issueResults: IssueResultsFragment | null;
	defaultJql: string;
	filter: FilterFragment | null;
	onFilterSave?: (filterId: string) => void;
	onReset?: (isResetToFilter?: boolean) => void;
	onChangeJql?: (jql: string, clearFilter?: boolean) => void;
	onToggleAi?: (isAiEnabled: boolean) => void;
	jqlBuilderProps?: OverridableJqlBuilderProps;
	jqlBuilderWithAiKey: JqlBuilderWithAiKey | null;
};

const JQLBuilderWrapper = ({
	defaultJql,
	onFilterSave,
	onReset,
	onChangeJql,
	onToggleAi,
	issueResults,
	filter,
	jqlBuilderProps,
	jqlBuilderWithAiKey,
}: Props) => {
	const { startJqlBuilderMetric, stopJqlBuilderMetric, markJqlBuilderMetric } =
		useJqlBuilderInstrumentation();

	useLayoutEffect(() => {
		startJqlBuilderMetric();
		markJqlBuilderMetric(marks.JQL_BUILDER_START);
	}, [startJqlBuilderMetric, markJqlBuilderMetric]);

	const { query, onSearch } = useJqlSearch(onChangeJql);

	const { jql, filterJql, filterId } = useActiveJql();
	const isAnonymous = useIsAnonymous();

	const { formatMessage } = useIntl();
	let jqlErrors: ExternalError[] | undefined;
	const { isFetching } = useIssueSearchQuery();

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults on JiraIssueConnection {
				issueSearchError {
					__typename
					... on JiraInvalidJqlError {
						messages
					}
					... on JiraInvalidSyntaxError {
						message
						errorType
					}
				}
			}
		`,
		issueResults,
	);

	const issueSearchError = issueResultsData?.issueSearchError ?? null;

	if (issueSearchError && !isFetching) {
		if (issueSearchError.__typename === 'JiraInvalidJqlError') {
			jqlErrors =
				issueSearchError.messages
					?.filter(isNonNullish)
					.map((message) => ({ type: 'error', message })) ?? [];
		} else if (
			issueSearchError.__typename === 'JiraInvalidSyntaxError' &&
			issueSearchError.message != null
		) {
			jqlErrors = [
				{
					type: 'error',
					message: issueSearchError.message,
					errorType: issueSearchError.errorType ?? undefined,
				},
			];
		}
	}

	const filterData = useFragment<FilterFragment>(
		graphql`
			fragment jqlBuilder_issueNavigator_JQLBuilderWrapper_filter on JiraCustomFilter {
				isEditable
			}
		`,
		filter,
	);

	const ResetButtonControl = useCallback(() => <ResetButton onReset={onReset} />, [onReset]);
	const GoBackToFilterButtonControl = useCallback(
		() => <ResetButton onReset={onReset} isResetToFilter />,
		[onReset],
	);

	const SaveFilterControl = useCallback(
		({ searchMode }: { searchMode: SearchMode }) => (
			<SaveFilterWrapper data-testid="issue-navigator.ui.jql-builder.save-filter-control">
				<SaveFilterDialog
					jql={query}
					attributes={{
						isAdvancedModeEnabled: searchMode === SEARCH_MODE_ADVANCED,
					}}
					onFilterSave={onFilterSave}
					buttonText={formatMessage(messages.saveFilterButton)}
					title={formatMessage(messages.saveFilterDialogTitle)}
				/>
			</SaveFilterWrapper>
		),
		[onFilterSave, query, formatMessage],
	);

	const SaveFilterDropdownControl = useCallback(
		() => (
			<SaveFilterWrapper data-testid="issue-navigator.ui.jql-builder.save-filter-dropdown-control">
				<SaveFilterDropdown query={query} filterId={filterId} onFilterSave={onFilterSave} />
			</SaveFilterWrapper>
		),
		[filterId, query, onFilterSave],
	);

	const CopyFilterControl = useCallback(
		({ searchMode }: { searchMode: SearchMode }) => (
			<SaveFilterWrapper data-testid="issue-navigator.ui.jql-builder.copy-filter-control">
				<SaveFilterDialog
					jql={query}
					filterId={filterId}
					attributes={{
						isAdvancedModeEnabled: searchMode === SEARCH_MODE_ADVANCED,
					}}
					onFilterSave={onFilterSave}
					buttonText={formatMessage(messages.copyFilterButton)}
					title={formatMessage(messages.copyFilterDialogTitle)}
				/>
			</SaveFilterWrapper>
		),
		[onFilterSave, query, filterId, formatMessage],
	);

	const renderFilterControls: NonNullable<JQLBuilderProps['renderExtraControls']> =
		useCallback(() => {
			const extraControls: ReturnType<typeof renderExtraControls> = [];

			// Button nor dropdown should be shown when user is anonymous
			if (!isAnonymous) {
				if (filterData) {
					const { isEditable } = filterData;

					// Should not show neither the button nor the dropdown if jql errors
					if (!jqlErrors || jqlErrors.length === 0) {
						if (isFilterJqlModified({ filterJql, query })) {
							if (isEditable === true) {
								extraControls.push(SaveFilterDropdownControl);
							} else {
								extraControls.push(SaveFilterControl);
							}
						} else {
							extraControls.push(CopyFilterControl);
						}
					}
				} else {
					extraControls.push(SaveFilterControl);
				}
			}

			return extraControls;
		}, [
			CopyFilterControl,
			SaveFilterControl,
			SaveFilterDropdownControl,
			filterData,
			filterJql,
			isAnonymous,
			jqlErrors,
			query,
		]);

	const renderExtraControls: NonNullable<JQLBuilderProps['renderExtraControls']> =
		useCallback(() => {
			const extraControls: ReturnType<typeof renderExtraControls> = [];

			const showResetButton =
				isOnlyJqlAndDifferentToDefault(jql, defaultJql, filterJql) ||
				isOnlyFilterAndDifferentToDefault(jql, defaultJql, filterJql) ||
				filterAndJqlIsTheSameAndDifferentToDefault(jql, defaultJql, filterJql);

			const showGoBackToFilterButton = jqlIsDifferentToFilter(jql, filterJql);

			const addResetButton = () => showGoBackToFilterButton || showResetButton;

			if (addResetButton()) {
				extraControls.push(showResetButton ? ResetButtonControl : GoBackToFilterButtonControl);
			}
			extraControls.push(...renderFilterControls());

			return extraControls;
		}, [
			renderFilterControls,
			jql,
			defaultJql,
			filterJql,
			ResetButtonControl,
			GoBackToFilterButtonControl,
		]);

	let jqlMessages: ExternalMessage[] = useMemo(() => [...(jqlErrors || [])], [jqlErrors]);

	const { messages: jqlInfos } = useJqlSearchStatus({ query, isSearching: isFetching });

	jqlMessages = useMemo(() => [...(jqlErrors || []), ...(jqlInfos || [])], [jqlErrors, jqlInfos]);

	const onLoad = useCallback(
		(mode: SearchMode) => {
			if (mode === SEARCH_MODE_BASIC) {
				markJqlBuilderMetric(marks.JQL_BUILDER_BASIC_START);
			} else {
				markJqlBuilderMetric(marks.JQL_BUILDER_ADVANCED_START);
			}
		},
		[markJqlBuilderMetric],
	);

	const onReady = useCallback(
		(mode: SearchMode) => {
			if (mode === SEARCH_MODE_BASIC) {
				markJqlBuilderMetric(marks.JQL_BUILDER_BASIC_END);
				markJqlBuilderMetric(marks.JQL_BUILDER_END);
			} else {
				markJqlBuilderMetric(marks.JQL_BUILDER_ADVANCED_END);
				markJqlBuilderMetric(marks.JQL_BUILDER_END);
			}
			stopJqlBuilderMetric();
		},
		[stopJqlBuilderMetric, markJqlBuilderMetric],
	);

	const aiBuilderOverrides = useMemo(
		() => ({
			// Only render filter controls (no reset action) for the AI embedded JQL builder
			renderExtraControls: renderFilterControls,
		}),
		[renderFilterControls],
	);

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const jqlBuilderWithAiData = useFragment<JqlBuilderWithAiKey>(
		graphql`
			fragment jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey on JiraQuery {
				...ui_jqlBuilderWithAi_JQLBuilderWithAI
			}
		`,
		jqlBuilderWithAiKey,
	);
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */

	const { onJourneyStart } = useAppContextualAnalyticsActions();

	const handlePerformanceMark = useCallback(
		({ mark }: { mark: string }) => {
			markJqlBuilderMetric(mark);
		},
		[markJqlBuilderMetric],
	);

	return (
		<Box xcss={[wrapperStyles, getWillShowNav4() && newWrapperStyles]}>
			<ShortcutsDialogIntegrationProvider>
				<JQLBuilder
					analyticsSource={ANALYTICS_SOURCE}
					query={query}
					onSearch={onSearch}
					renderExtraControls={renderExtraControls}
					jqlMessages={jqlMessages}
					isSearching={isFetching}
					onReady={onReady}
					onLoad={onLoad}
					onJourneyStart={onJourneyStart}
					NoOptionComponent={NoOptionsMessage}
					aiBuilderOverrides={aiBuilderOverrides}
					onToggleAi={onToggleAi}
					fragmentKey={jqlBuilderWithAiData}
					onPerformanceMark={handlePerformanceMark}
					{...jqlBuilderProps}
				/>
			</ShortcutsDialogIntegrationProvider>
		</Box>
	);
};

const JQLBuilderWrapperWithErrorBoundary = (props: Props) => (
	<UFOSegment name="issue-navigator-jql-builder">
		<JSErrorBoundary
			id="ui.jql-builder.error-boundary"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			sendToPrivacyUnsafeSplunk
			attributes={isLoaderErrorAttributes}
			fallback={() => <JQLBuilderErrorBoundryFallback />}
		>
			<JQLBuilderWrapper {...props} />
		</JSErrorBoundary>
	</UFOSegment>
);

export default JQLBuilderWrapperWithErrorBoundary;

const wrapperStyles = xcss({
	marginTop: 'space.negative.025',
	marginBottom: 'space.0',
});
const newWrapperStyles = xcss({
	flexGrow: 'var(--jql-wrapper-grow, 0)',
	maxHeight: 'min-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SaveFilterWrapper = styled.div({
	marginTop: token('space.050', '4px'),
});
