import React from 'react';
import { useActionsCommandPalette } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useShortcutsActions } from '@atlassian/jira-shortcuts-dialog/src/controllers/shortcuts-service/index.tsx';

const RegisterDotShortcut = () => {
	const [, { setShortcutsDialogVisibility }] = useShortcutsActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { open } = useActionsCommandPalette();

	return (
		<Shortcuts
			keyMap={{
				'.': {
					callback: () => {
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'pressed',
								actionSubject: 'keyboardShortcut',
							}),
							'dotDialogKeyboardShortcut',
							{
								keyPressed: '.',
								keyboardShortcut: true,
							},
						);

						if (
							ff('compal-dot-menu-shortcut-integration_dvvg2') &&
							ff('compal-sunset-and-remove-dot-menu_66j8q')
						) {
							open();
						} else {
							requestAnimationFrame(() => setShortcutsDialogVisibility(true));
						}
					},
				},
			}}
		/>
	);
};

export default RegisterDotShortcut;
