import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Link from '@atlaskit/link';
import LinkOld from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { projectRoleGrant_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/projectRoleGrant_issueNavigatorCustomFilters.graphql';
import { SectionBody } from '../styled';
import messages from './messages';

type Props = {
	entity: projectRoleGrant_issueNavigatorCustomFilters$key;
};

const linkStart = '{linkStart}';
const linkEnd = '{linkEnd}';

const ConditionalLink = componentWithFG('nin_serious_accessibility_issues', Link, LinkOld);

export const ProjectRoleGrant = ({ entity }: Props) => {
	const { formatMessage } = useIntl();

	const projectRoleGrant = useFragment(
		graphql`
			fragment projectRoleGrant_issueNavigatorCustomFilters on JiraShareableEntityProjectRoleGrant {
				project {
					name
					key
				}
				role {
					name
				}
			}
		`,
		entity,
	);

	const message = formatMessage(messages.projectRoleNameLink, {
		name: projectRoleGrant.project?.name,
		role: projectRoleGrant.role?.name,
		linkStart,
		linkEnd,
	});

	return (
		<SectionBody>
			{projectRoleGrant.project?.name !== undefined &&
				projectRoleGrant.role?.name !== undefined && (
					<FormattedI18nMessage
						componentsMapping={{
							link: ({ children }) =>
								projectRoleGrant.project?.key ? (
									<ConditionalLink
										href={`/browse/${projectRoleGrant.project?.key}`}
										onClick={(_: unknown, analyticsEvent: UIAnalyticsEvent) =>
											fireUIAnalytics(analyticsEvent, 'projectRoleGrant')
										}
									>
										{children}
									</ConditionalLink>
								) : (
									<>{children}</>
								),
						}}
						message={message}
					/>
				)}
		</SectionBody>
	);
};
