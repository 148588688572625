import React from 'react';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import { StyledBox, StyledText } from './styled';

type PageAction = 'exportIssues' | 'meatballMenu' | 'apps' | 'detailsPopup' | 'columnPicker';

type ErrorStateProps = {
	pageAction: PageAction;
	description?: string;
	onRetry: () => void | Promise<void>;
	role?: string;
};

const BOX_HEIGHT = gridSize * 18;
const ERROR_ICON_BOX_WRAPPER_HEIGHT = gridSize * 9;
const ICON_SIZE = gridSize * 9;

export const ErrorState = (props: ErrorStateProps) => {
	const { pageAction, onRetry, description, role } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const onClick = () => {
		onRetry();
		fireOperationalAnalytics(createAnalyticsEvent({}), `${pageAction}Retry clicked`);
	};
	return (
		<StyledBox height={BOX_HEIGHT} role={role}>
			<StyledBox height={ERROR_ICON_BOX_WRAPPER_HEIGHT} iconSize={ICON_SIZE}>
				<ErrorIcon color={token('color.icon')} LEGACY_primaryColor={token('color.icon')} label="" />
			</StyledBox>
			<StyledText>
				{description !== undefined ? description : formatMessage(messages.errorMessage)}
			</StyledText>
			<Button
				onClick={onClick}
				appearance="link"
				testId="issue-navigator-actions-common.ui.error.button"
			>
				{formatMessage(messages.retryMessage)}
			</Button>
		</StyledBox>
	);
};
