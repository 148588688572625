import React, { type ReactNode } from 'react';
import type { JqlUtils as JqlUtilsType } from '@atlassian/jira-jql-utils/src/main.tsx';
import {
	createStore,
	createContainer,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';
import type { SortDirection } from '../../common/types';
import { useJqlUtils } from '../use-jql-utils';

type StoreType = {
	field: string | undefined;
	direction: SortDirection | undefined;
};
type PropType = {
	onChangeJql?: (jql: string) => void;
	jql: string;
	JqlUtils: JqlUtilsType | undefined;
};
type ContainerProps = {
	children: ReactNode;
	jql: string;
	onChangeJql?: (jql: string) => void;
};
type ActionType = {
	setFieldAndDirection: (
		field: string,
		direction: SortDirection | undefined,
		aliases: string[],
	) => Action<StoreType, PropType>;
	// setDirection: (direction: SortDirection) => Action<StoreType, PropType>,
	setField: (field: string, aliases: string[]) => Action<StoreType, PropType>;
};

const Store = createStore<StoreType, ActionType>({
	initialState: { field: 'created', direction: 'DESC' },
	actions: {
		setField:
			(field, aliases) =>
			({ getState, setState }, { onChangeJql, jql, JqlUtils }) => {
				const { field: prevField } = getState();
				if (field !== prevField) {
					setState({ field });
					const newJql = JqlUtils?.jql(jql).setPrimarySortField(field, undefined, aliases).toJql();
					typeof newJql === 'string' && onChangeJql && onChangeJql(newJql);
				}
			},
		setFieldAndDirection:
			(field, direction, aliases) =>
			({ getState, setState }, { onChangeJql, jql, JqlUtils }) => {
				const { field: prevField, direction: prevDirection } = getState();
				if (field !== prevField || direction !== prevDirection) {
					setState({ field, direction });
					// TODO EM-4079 - remove this and update types once old issue table FF is cleaned up
					if (direction !== 'NONE') {
						const newJql = JqlUtils?.jql(jql)
							.setPrimarySortField(field, direction, aliases)
							.toJql();
						typeof newJql === 'string' && onChangeJql && onChangeJql(newJql);
					}
				}
			},
	},
	name: 'IssueNavigatorSelectedViewState',
});

export const setFieldAndDirectionInState =
	(): Action<StoreType, PropType> =>
	({ getState, setState }, { jql, JqlUtils }) => {
		const field = JqlUtils?.jql(jql).getPrimarySortField();
		const direction = JqlUtils?.jql(jql).getPrimarySortDirection();
		const { field: prevField, direction: prevDirection } = getState();
		if (field !== prevField || direction !== prevDirection) {
			setState({ field, direction });
		}
	};

export const Container = createContainer<StoreType, ActionType, PropType>(Store, {
	onInit: setFieldAndDirectionInState,
	onUpdate: setFieldAndDirectionInState,
});

export const SortContainer = (props: ContainerProps) => {
	const Utils = useJqlUtils();
	return <Container JqlUtils={Utils} {...props} />;
};

export const useSortFieldAndDirection = createHook(Store, {
	selector: ({ field, direction }) => ({ field, direction }),
});
