import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useSelectedIssueKey as useSelectedIssueKeyOld,
	useSelectedIssueIndex as useSelectedIssueIndexOld,
	useSelectedIssueDebouncedState as useSelectedIssueDebouncedStateOld,
	useIsFullPageIssueAppMode as useIsFullPageIssueAppModeOld,
} from '../selected-issue-state-old';
import {
	useSelectedIssueKey as useSelectedIssueKeyNew,
	useSelectedIssueIndex as useSelectedIssueIndexNew,
	useSelectedIssueDebouncedState as useSelectedIssueDebouncedStateNew,
	useIsFullPageIssueAppMode as useIsFullPageIssueAppModeNew,
} from './hooks.tsx';

export const useSelectedIssueKey = () =>
	expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueKeyNew()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueKeyOld();

export const useSelectedIssueIndex = () =>
	expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueIndexNew()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueIndexOld();

export const useSelectedIssueDebouncedState = () =>
	expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueDebouncedStateNew()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueDebouncedStateOld();

export const useIsFullPageIssueAppMode = () =>
	expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsFullPageIssueAppModeNew()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsFullPageIssueAppModeOld();
