/** @jsx jsx */
import React, { type ComponentType, useMemo, type ReactNode } from 'react';
import { jsx, cssMap } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { PageTopBar } from '@atlassian/jira-horizontal-nav-page-topbar/src/ui/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { topBar_issueNavigator_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_filter.graphql';
import type {
	topBar_issueNavigator_issueResults$key as IssueResultsFragment,
	topBar_issueNavigator_issueResults$data as IssueResultsData,
} from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_issueResults.graphql';
import UFOLabel from '@atlassian/jira-ufo-label';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants';
import type { CustomHeaderProps } from '../../common/types';
import { withReportErrors } from '../../common/ui/with-report-errors';
import { useActiveJql, useResolvedJql } from '../../services/active-jql';
import { useFilterQuery } from '../../services/filter-query';

export type TopBarProps = {
	ActionMenu: ComponentType<CustomHeaderProps>;
	filter: FilterFragment | null;
	issueResults: IssueResultsFragment | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderSkeletonImage: ComponentType<Record<any, any>>;
	jqlBuilder: ReactNode;
};

const getLastPage = (
	pageCursors: IssueResultsData['pageCursors'] | undefined,
): number | null | undefined => {
	if (pageCursors?.last) {
		return pageCursors.last.pageNumber;
	}

	if (pageCursors?.around?.length) {
		return pageCursors.around[pageCursors.around.length - 1]?.pageNumber;
	}

	return undefined;
};

const styles = cssMap({
	jqlWrapper: {
		'--jql-wrapper-grow': '1',
	},
});

const TopBar = ({
	ActionMenu,
	filter,
	issueResults,
	HeaderSkeletonImage,
	jqlBuilder,
}: TopBarProps) => {
	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment topBar_issueNavigator_issueResults on JiraIssueConnection {
				issueNavigatorPageInfo {
					firstIssuePosition
				}
				pageCursors(maxCursors: 7) {
					around {
						pageNumber
					}
					last {
						pageNumber
					}
				}
				totalIssueSearchResultCount
			}
		`,
		issueResults,
	);

	// Compute page data for our search results which is needed when requesting items for the legacy meatball menu
	const offset = (issueResultsData?.issueNavigatorPageInfo?.firstIssuePosition ?? 1) - 1; // offset is 0 based so subtract 1
	const pages = getLastPage(issueResultsData?.pageCursors) ?? 0;
	const total = issueResultsData?.totalIssueSearchResultCount ?? 0;

	const searchResultPageData = useMemo(
		() => ({
			offset,
			pages,
			total,
		}),
		[offset, pages, total],
	);

	const { filterId } = useActiveJql();
	const jql = useResolvedJql();
	const { filterQueryIsFetching } = useFilterQuery();

	const filterData = useFragment(
		graphql`
			fragment topBar_issueNavigator_filter on JiraFilter {
				filterId
				name
				isFavourite
				jql
			}
		`,
		filter,
	);
	const filterDetails = useMemo(
		() =>
			filterData
				? {
						filterId: filterData.filterId,
						name: filterData.name,
						jql: filterData.jql,
						isFavourite: Boolean(filterData.isFavourite),
					}
				: undefined,
		[filterData],
	);

	if (filterQueryIsFetching) {
		return <HeaderSkeletonImage />;
	}

	const pageTopBar = (
		<PageTopBar
			actions={
				<ActionMenu
					jql={jql}
					filterId={filterId}
					filterDetails={filterDetails}
					searchResultPageData={searchResultPageData}
				/>
			}
			bottomBar={jqlBuilder}
		/>
	);

	if (getWillShowNav4()) {
		return (
			<UFOLabel name="top-bar">
				<div css={styles.jqlWrapper}>{pageTopBar}</div>
			</UFOLabel>
		);
	}
	return <UFOLabel name="top-bar">{pageTopBar}</UFOLabel>;
};

export default withReportErrors<TopBarProps>(TopBar, {
	id: 'ui.topbar.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});
