import React, { memo, type ComponentType } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { IssueTableSkeleton } from '../common/ui/skeleton';
import type { Props } from './types';

const AsyncNativeIssueTable = lazyForPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-issue-navigator-native-issue-table" */ './index'),
);

const AsyncNativeIssueTableWithWrapper: ComponentType<Props> = memo<Props>((props: Props) => (
	<Placeholder fallback={<IssueTableSkeleton />} name="async-native-issue-table">
		<AsyncNativeIssueTable {...props} />
	</Placeholder>
));

export default AsyncNativeIssueTableWithWrapper;
