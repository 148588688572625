import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Get feature flags and feature gates for the issue navigator performance metrics
 */
export const getIssueNavigatorFFAndFG = () => ({
	isCohorted: ff('nin.global-scope_aqec8'),
	isNinExposeTextOption: ff('nin_expose_text_option_jql_builder_4kwzo'),
	isNinIssueLinksFieldNativeCellEnabled: ff(
		'extend-popup-cell-with-custom-override-content-and-parameters_obikd',
	),
	isNinShowFieldOptionsAsOnlyUnselectableEnabled: ff(
		'nin-show-field-options-as-only-unselectable_0s1y2',
	),
	isNinShowOutOfContextFieldsAsOnlyUnselectableEnabled: ff(
		'nin-enable-show-out-of-context-fields-as-only-unselectable_lmsy7',
	),
	isJscM1ApiUpdatesEnabled: fg('jira_spreadsheet_component_m1_api_updates'),
});
