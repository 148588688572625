import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal';
import { createStore, createContainer } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State, Actions, Props } from './types.tsx';

const initialState: State = {
	disposables: [],
	isFetching: false,
	networkError: false,
	searchKey: null,
};

export const IssueSearchServiceContainer = createContainer<Props>();

export const Store = createStore<State, Actions, Props>({
	initialState,
	actions,
	containedBy: IssueSearchServiceContainer,
	handlers: {
		onContainerUpdate:
			(nextProps, prevProps) =>
			({ dispatch }) => {
				const hasIssueSearchInputChanged = !isShallowEqual(
					nextProps.issueSearchInput,
					prevProps.issueSearchInput,
				);
				if (hasIssueSearchInputChanged) {
					dispatch(actions.onRefetch());
				}
			},
		onDestroy:
			() =>
			({ getState }) => {
				const { disposables } = getState();
				// Dispose of any manually retained operations when the container is unmounted
				disposables.forEach((disposable) => disposable.dispose());
			},
	},
});
