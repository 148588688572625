import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadedIssuesTotalKnown: {
		id: 'issue-navigator.common.page-text.loaded-issues-total-known',
		defaultMessage: '{loadedIssuesCount} of {totalNumberIssues}',
		description: 'Text indicating the loaded range out of the total number of issues.',
	},
	issueRangeKnown: {
		// i18n ID doesn't match package, as this message has been moved but we
		// do not want the translations to become invalidated
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'issue-navigator.card-container.paginator.issue-range-known',
		defaultMessage: '{startIssueRange}-{endIssueRange} of {totalNumberIssues}',
		description: 'Text indicating the shown range out of the total number of issues.',
	},
	issueRangeUnknown: {
		// i18n ID doesn't match package, as this message has been moved but we
		// do not want the translations to become invalidated
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'issue-navigator.card-container.paginator.issue-range-unknown',
		defaultMessage: '{startIssueRange}-{endIssueRange}',
		description: 'Text indicating the shown range only.',
	},
});
