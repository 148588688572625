import React, { type ReactElement } from 'react';
import type { SecondaryLabelComponent } from '../types';

export const getSecondaryLabelWithIcon = (
	icon: ReactElement,
	fieldTypeDisplayName: string,
): SecondaryLabelComponent => ({
	label: fieldTypeDisplayName,
	element: (
		<>
			{icon}
			{fieldTypeDisplayName}
		</>
	),
});
