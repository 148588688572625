import { useCallback } from 'react';
import { metrics } from '@atlassian/browser-metrics';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useSelectedViewState } from '../selected-view-state';

// TODO Define SLO's/histogram based on prod timings
export const NINIssueAppSegment = metrics.pageSegmentLoad({
	key: 'new-issue-navigator.issue-app',
});

/**
 * Start a BM3 page segment load metric to track rendering of the issue app in NIN.
 */
export const useStartNinIssueAppAnalytics = (issueKey: string) => {
	const [{ view }] = useSelectedViewState();
	const previousIssueKey = usePrevious(issueKey);

	// We explicitly start during render instead of an effect hook as onInteractive can be fired before the effect hook
	// has run for cached issues.
	if (issueKey !== previousIssueKey) {
		NINIssueAppSegment.start();
	}

	const onInteractive = useCallback(() => {
		NINIssueAppSegment.stop({
			customData: {
				view,
			},
		});
	}, [view]);

	return { onInteractive };
};
