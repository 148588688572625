/**
 * @generated SignedSource<<106cdbf5ca3c146af8085a03cdbeac30>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_issueSearchData$data = {
  readonly jira: {
    readonly issueSearch: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_issueResults" | "selectedIssue_issueNavigator">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_issueSearchData";
};
export type main_issueNavigator_IssueNavigator_issueSearchData$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_issueSearchData$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
};

import IssueNavigatorIssueSearchPaginationQuery_graphql from './IssueNavigatorIssueSearchPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "issueSearch"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "options"
    },
    {
      "kind": "RootArgument",
      "name": "queryStatusForIssuekeyStrikethrough"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": IssueNavigatorIssueSearchPaginationQuery_graphql
    }
  },
  "name": "main_issueNavigator_IssueNavigator_issueSearchData",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "alias": "issueSearch",
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Variable",
              "name": "issueSearchInput",
              "variableName": "issueSearchInput"
            },
            {
              "kind": "Variable",
              "name": "options",
              "variableName": "options"
            }
          ],
          "concreteType": "JiraIssueConnection",
          "kind": "LinkedField",
          "name": "__IssueNavigatorIssueSearchPagination__issueSearch_connection",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "cursor"
                },
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "selectedIssue_issueNavigator"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_IssueNavigatorUI_issueResults"
            },
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "endCursor"
                },
                {
                  "kind": "ScalarField",
                  "name": "hasNextPage"
                },
                {
                  "kind": "ScalarField",
                  "name": "hasPreviousPage"
                },
                {
                  "kind": "ScalarField",
                  "name": "startCursor"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "d2ac9ad04959fd7c243cb7854cfda4a3";

export default node;
