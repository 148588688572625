import React from 'react';
import { ContainerContextSubscriber } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { isProjectContext } from '@atlassian/jira-providers-container-context/src/types.tsx';
import { SubProductUpdater } from '../sub-product-updater';
import { getSubProductFromProjectType } from '../transformations';

export const ProjectSubProductUpdater: Flow.AbstractComponent<{
	allowMonolithDeferral?: boolean;
}> = ({ allowMonolithDeferral = false }) => (
	<ContainerContextSubscriber>
		{({ data }) => (
			<>
				{data && isProjectContext(data) && (
					<SubProductUpdater
						subProduct={getSubProductFromProjectType(data.project.type)}
						allowMonolithDeferral={allowMonolithDeferral}
					/>
				)}
			</>
		)}
	</ContainerContextSubscriber>
);
