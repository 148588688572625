import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, useMutation } from 'react-relay';
import Button from '@atlaskit/button';
import DropdownMenu, {
	type OnOpenChangeArgs,
	DropdownItem,
	DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagService, toFlagId } from '@atlassian/jira-flags';
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorFlag } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error-flag/index.tsx';
import { SuccessFlag } from '@atlassian/jira-issue-navigator-actions-common/src/ui/success-flag/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { mainUpdateFilterJqlMutation } from '@atlassian/jira-relay/src/__generated__/mainUpdateFilterJqlMutation.graphql';
import { AsyncSaveFilterDialogContent as SaveFilterDialogContent } from '@atlassian/jira-save-filter-dialog/src/ui/save-filter-dialog/save-filter-dialog-content/async.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import ThirdPartyNudge from '@atlassian/jira-third-party-nudge/src/ui/async.tsx';
import messages from './messages';

export type Props = {
	defaultOpen?: boolean;
	filterId?: string;
	query: string;
	onFilterSave?: (filterId: string) => void;
};

export const SUCCESS_FLAG_ID = toFlagId('issue-navigator-save-filter-success-flag');
export const ERROR_FLAG_ID = toFlagId('issue-navigator-save-filter-error-flag');

export const SaveFilterDropdown = ({
	defaultOpen = false,
	filterId,
	query,
	onFilterSave,
}: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [updateJiraCustomFilterJqlMutation] = useMutation<mainUpdateFilterJqlMutation>(graphql`
		mutation mainUpdateFilterJqlMutation($id: ID!, $jql: String!) {
			jira {
				jiraFilterMutation {
					updateJiraCustomFilterJql(input: { id: $id, jql: $jql }) {
						success
						errors {
							message
						}
						filter {
							id
							name
							jql
						}
					}
				}
			}
		}
	`);

	const { activationId, cloudId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({});
	const onSaveChanges = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'saveFilterChanges',
		);
		filterId !== undefined &&
			updateJiraCustomFilterJqlMutation({
				variables: {
					id: createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'filter',
						resourceId: filterId,
						activationId,
					}),
					jql: query,
				},
				onCompleted: ({ jira }) => {
					if (jira?.jiraFilterMutation?.updateJiraCustomFilterJql) {
						const {
							success,
							filter,
							errors: jiraErrors,
						} = jira?.jiraFilterMutation?.updateJiraCustomFilterJql;

						if (success && filter) {
							const { name } = filter;
							fireTrackAnalytics(
								createAnalyticsEvent({
									actionSubject: 'issueFilterJql',
									action: 'updated',
								}),
								'issueFilterJql updated',
							);
							showFlag({
								id: SUCCESS_FLAG_ID,
								render: (renderProps: FlagCustomRenderProps) => (
									<SuccessFlag
										id={SUCCESS_FLAG_ID}
										filterName={name}
										// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
										viewUrl={`${window.location.origin}/issues/?filter=${filterId}`}
										analyticsEvent={createAnalyticsEvent({
											actionSubject: 'button',
											action: 'clicked',
										})}
										{...renderProps}
									/>
								),
							});
						} else {
							const errors = jiraErrors ? jiraErrors.map((error) => error.message || '') : [];
							showFlag({
								id: ERROR_FLAG_ID,
								render: (renderProps: FlagCustomRenderProps) => (
									<ErrorFlag id={ERROR_FLAG_ID} errors={errors} {...renderProps} />
								),
							});
							fireErrorAnalytics({
								event: analyticsEvent,
								error: new Error(errors[0]),
								meta: {
									id: 'updateFilterJqlMutation',
									packageName: 'jiraIssueNavigatorCustomFilters',
									teamName: 'empanada',
								},
							});
						}
					}
				},
				onError: (error) => {
					showFlag({
						id: ERROR_FLAG_ID,
						render: (renderProps: FlagCustomRenderProps) => (
							<ErrorFlag id={ERROR_FLAG_ID} {...renderProps} errors={['']} />
						),
					});
					fireErrorAnalytics({
						event: analyticsEvent,
						error,
						meta: {
							id: 'updateFilterJqlMutation',
							packageName: 'jiraIssueNavigatorCustomFilters',
							teamName: 'empanada',
						},
					});
					return undefined;
				},
			});
	}, [
		activationId,
		cloudId,
		createAnalyticsEvent,
		filterId,
		query,
		showFlag,
		updateJiraCustomFilterJqlMutation,
		analyticsEvent,
	]);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(defaultOpen);
	const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = useState(false);

	const onOpenSaveFilterModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'saveFilterCopy',
		);
		setIsModalOpen(true);
	}, [createAnalyticsEvent, setIsModalOpen]);

	const onCloseSaveFilterModal = useCallback(() => {
		setIsModalOpen(false);
	}, [setIsModalOpen]);

	const onTriggerClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'dropdownMenu',
			}),
			'filterOptions',
		);
	}, [createAnalyticsEvent]);

	return (
		<>
			{shouldShowThirdPartyNudge && <ThirdPartyNudge />}
			<DropdownMenuWrapper>
				<DropdownMenu
					isOpen={isDropdownOpen}
					onOpenChange={(attrs: OnOpenChangeArgs) => {
						setIsDropdownOpen(attrs.isOpen);
						if (attrs.isOpen) {
							setShouldShowThirdPartyNudge(true);
							onTriggerClick();
						}
					}}
					trigger={({ triggerRef, ...props }) => (
						<Button
							{...props}
							appearance="link"
							ref={triggerRef}
							iconAfter={<ChevronDown label={formatMessage(messages.saveFilter)} />}
						>
							{formatMessage(messages.saveFilter)}
						</Button>
					)}
				>
					<DropdownItemGroup>
						<DropdownItem onClick={onSaveChanges}>
							{formatMessage(messages.saveChanges)}
						</DropdownItem>
						<DropdownItem onClick={onOpenSaveFilterModal}>
							{formatMessage(messages.saveAsACopy)}
						</DropdownItem>
					</DropdownItemGroup>
				</DropdownMenu>
			</DropdownMenuWrapper>
			<SaveFilterDialogContent
				isOpen={isModalOpen}
				jql={query}
				filterId={filterId}
				onCloseModal={onCloseSaveFilterModal}
				onFilterSave={onFilterSave}
				title={formatMessage(messages.copyFilterDialogTitle)}
			/>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-role='droplistContent']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: '218px !important',
	},
});
