import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

const ANOTHER_FILTER_LINK = '/jira/filters';

export const NoFilter = ({ reason }: { reason?: string }) => {
	const { formatMessage } = useIntl();

	return (
		<ContextualAnalyticsData sourceName="noFilter" sourceType={SCREEN}>
			<FireScreenAnalytics
				attributes={
					reason
						? {
								reason,
							}
						: undefined
				}
			/>
			<EmptyState
				header={formatMessage(messages.weCouldntFindThisFilter)}
				description={
					<>
						<p>{formatMessage(messages.filterNoExistOrNoPermissions)}</p>
						<p>{formatMessage(messages.tryAnotherFilter)}</p>
					</>
				}
				secondaryAction={
					<Button href={ANOTHER_FILTER_LINK}>{formatMessage(messages.viewFilters)}</Button>
				}
				primaryAction={
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					<Button appearance="primary" href={window.location.pathname}>
						{formatMessage(messages.searchIssues)}
					</Button>
				}
				renderImage={() => (
					<Box xcss={ImageWrapperStyles}>
						<SearchNoResultsImage alt="" />
					</Box>
				)}
			/>
		</ContextualAnalyticsData>
	);
};

export default NoFilter;

const ImageWrapperStyles = xcss({
	maxHeight: '146px',
	maxWidth: '160px',
	margin: 'auto',
	marginBottom: 'space.300',
});
