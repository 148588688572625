import { useCallback, useState, type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getTraceIdJq } from '@atlassian/jira-fetch/src/utils/trace-id';
import { useFlagService } from '@atlassian/jira-flags';
import { fireUIAnalytics, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider$key } from '@atlassian/jira-relay/src/__generated__/addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider.graphql';
import { asyncWrmRequire } from '@atlassian/jira-wrm/src/async-wrm.tsx';
import { ANALYTICS_PACKAGE_NAME } from '../../../../../common/constants';
import { getManageSubscriptionsLink } from '../../../../../common/utils';
import { viewNewSubscriptionModal } from '../../../../../experiences';
import messages from './messages';

const WRM_RESOURCES = ['wrc!jira.webresources:croneditor'];

const preload = () => {
	asyncWrmRequire(WRM_RESOURCES);
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getFormDialog = () => asyncWrmRequire(WRM_RESOURCES).then(() => window.JIRA.FormDialog);

type Props = {
	filter: addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider$key;
	children: (props: { isLoading: boolean; open: () => void; preload: () => void }) => ReactElement;
	onError?: (error: Error) => void;
	onOpenNewSubscriptionModal?: () => void;
	onSuccess?: () => void;
};

type SmartAjaxResult = {
	successful: boolean;
	status: number;
	statusText: string;
	errorThrown: Error;
	readyState: number;
	hasData: boolean;
	data: null | undefined | string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	xhr: any;
	aborted: boolean;
	requestId: number;
	validationError: boolean;
};

export const AddSubscriptionModalProvider = ({
	filter,
	children,
	onError,
	onOpenNewSubscriptionModal,
	onSuccess,
}: Props) => {
	const [isLoading, setLoading] = useState(false);
	const { showFlag } = useFlagService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const filterData = useFragment(
		graphql`
			fragment addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider on JiraCustomFilter {
				filterId @required(action: THROW)
				name @required(action: THROW)
				description
				owner {
					accountId @required(action: THROW)
				}
			}
		`,
		filter,
	);

	const onSuccessfulSubmit = useCallback(() => {
		fireTrackAnalytics(
			createAnalyticsEvent({
				actionSubject: 'issueFilterSubscription',
				action: 'created',
			}),
			'issueFilterSubscription created',
			{
				issueFilterNameLength: filterData.name.length,
				issueFilterDescriptionLength: filterData.description?.length,
				issueFilterOwner: filterData.owner?.accountId,
			},
		);
		onSuccess && onSuccess();
	}, [createAnalyticsEvent, filterData, onSuccess]);

	const open = useCallback(() => {
		(async () => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'addFilterSubscription',
			);
			viewNewSubscriptionModal.start();
			setLoading(true);
			try {
				const FormDialog = await getFormDialog();

				/**
				 * Available options can be found in:
				 * https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-webapp-resources/src/main/webapp/includes/jira/dialog/FormDialog.js
				 * Look at `_getDefaultOptions` function and accesses to `this.options`
				 */
				const dialog = new FormDialog({
					ajaxOptions: {
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						url: `https://${window.location.hostname}/secure/EditSubscription!default.jspa?filterId=${filterData.filterId}`,
						data: {
							decorator: 'dialog',
							inline: 'true',
						},
					},
					autoClose: true,
					onUnSuccessfulSubmit(
						statusText: string,
						errorThrown: Error | undefined,
						smartAjaxResult: SmartAjaxResult,
					) {
						const error =
							errorThrown ||
							new FetchError(smartAjaxResult.status, undefined, getTraceIdJq(smartAjaxResult.xhr));

						fireErrorAnalytics({
							meta: {
								id: 'AddSubscriptionModalProvider.onUnSuccessfulSubmit',
								packageName: ANALYTICS_PACKAGE_NAME,
								teamName: 'empanada',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
						onError && onError(error);
					},
					onDialogFinished() {
						onSuccessfulSubmit();
						showFlag({
							type: 'success',
							title: messages.successFlagTitle,
							description: [messages.successFlagDescription, { filterName: filterData.name }],
							actions: [
								{
									content: messages.successFlagActionManage,
									href: getManageSubscriptionsLink(filterData.filterId),
									target: '_blank',
								},
							],
						});
					},
				});
				dialog.onContentReadyCallbacks?.push(() => {
					viewNewSubscriptionModal.success();
				});

				dialog.show();
				onOpenNewSubscriptionModal && onOpenNewSubscriptionModal();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				showFlag({
					type: 'error',
					title: messages.showModalErrorTitle,
					description: messages.showModalErrorDescription,
					error,
				});
				fireErrorAnalytics({
					meta: {
						id: 'AddSubscriptionModalProvider',
						packageName: ANALYTICS_PACKAGE_NAME,
						teamName: 'empanada',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
				viewNewSubscriptionModal.failure();
			}
			setLoading(false);
		})();
	}, [
		createAnalyticsEvent,
		filterData,
		showFlag,
		onError,
		onOpenNewSubscriptionModal,
		onSuccessfulSubmit,
	]);

	return children({ isLoading, open, preload });
};
