import React, { useCallback, useEffect, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_FullPageIssueApp$key as FullPageIssueAppKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_FullPageIssueApp.graphql';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOLabel from '@atlassian/jira-ufo-label';
import { ANALYTICS_LIST_VIEW_SOURCE_NAME } from '../../common/constants';
import IssueApp from '../../common/ui/issue-app/main';
import {
	useIsForcedSelectionOnReset,
	useSelectedIssueStateOldActions,
} from '../../controllers/selected-issue-state-old';
import { useSelectedIssueActions } from '../../controllers/selected-issue/hooks.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query';
import messages from './messages';

type Props = {
	issueResults: FullPageIssueAppKey | null;
	onChangeIssue: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
};

const FullPageIssueApp = ({ onChangeIssue, issueResults }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { isFetching } = useIssueSearchQuery();

	// TODO Remove this condition when cleaning up jira_spreadsheet_component_m1
	const isForcedSelectionOnReset =
		!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) &&
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useIsForcedSelectionOnReset();

	const { exitFullPageIssueAppMode, selectNextIssue, selectPreviousIssue } = expVal(
		'jira_spreadsheet_component_m1',
		'isInfiniteScrollingEnabled',
		false,
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueActions()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueStateOldActions();

	const onBackToSearch = useCallback(() => {
		// Clear the selected issue key from the URL and return to normal view mode
		onChangeIssue(toIssueKey(''), true);
		exitFullPageIssueAppMode();
	}, [exitFullPageIssueAppMode, onChangeIssue]);

	const onBackToSearchByButton = useCallback(
		(e: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			// @ts-expect-error - Argument of type 'UIAnalyticsEvent | null' is not assignable to parameter of type 'UIAnalyticsEvent'.
			fireUIAnalytics(analyticsEvent.clone(), 'returnToSearch');
			onBackToSearch();
		},
		[onBackToSearch],
	);

	const onBackToSearchByKeyboardShortcut = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'issue',
			}),
			'returnToSearch',
			{
				keyPressed: 'z',
				keyboardShortcut: true,
			},
		);
		onBackToSearch();
	}, [onBackToSearch, createAnalyticsEvent]);

	const onNextIssue = useCallback(() => {
		if (selectNextIssue()) {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'nextIssue',
				{ keyboardShortcut: true },
			);
		}
	}, [createAnalyticsEvent, selectNextIssue]);

	const onPreviousIssue = useCallback(() => {
		if (selectPreviousIssue()) {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'previousIssue',
				{ keyboardShortcut: true },
			);
		}
	}, [createAnalyticsEvent, selectPreviousIssue]);

	// If a new search is being run and we do not have forced issue selection (i.e. issue app pagination) then return to
	// normal view mode. This can happen when a filter is clicked in the sidebar or browser history navigation.
	useEffect(() => {
		if (isFetching && !isForcedSelectionOnReset) {
			exitFullPageIssueAppMode();
		}
	}, [exitFullPageIssueAppMode, isFetching, isForcedSelectionOnReset]);

	const backToSearch = (
		<Button
			testId="issue-navigator.ui.full-page-issue-app.return-to-search"
			onClick={onBackToSearchByButton}
			interactionName="issue-navigator-full-page-issue-app-return-to-search"
		>
			{formatMessage(messages.returnText)}
		</Button>
	);

	const issueResultsData = useFragment<FullPageIssueAppKey>(
		graphql`
			fragment main_issueNavigator_FullPageIssueApp on JiraIssueConnection {
				...main_issueNavigator_IssueAppWithData
			}
		`,
		issueResults,
	);

	return (
		<UFOLabel name="full-page-issue-app">
			<ContextualAnalyticsData sourceName={ANALYTICS_LIST_VIEW_SOURCE_NAME} sourceType={SCREEN}>
				<Shortcuts
					keyMap={{
						z: {
							callback: onBackToSearchByKeyboardShortcut,
						},
						j: {
							callback: onNextIssue,
						},
						k: {
							callback: onPreviousIssue,
						},
					}}
				/>
				<IssueApp
					isEmbedView={false}
					extraHeaderActions={backToSearch}
					issueResults={issueResultsData}
				/>
			</ContextualAnalyticsData>
		</UFOLabel>
	);
};

export default FullPageIssueApp;
