import { statusCategories } from '@atlassian/jira-common-constants';

const { DONE } = statusCategories;
export const shouldDisplayStrikethrough = (
	isResolved: boolean | undefined,
	statusCategory: string | undefined,
) => {
	if (isResolved !== undefined) {
		return isResolved;
	}

	// In JSW, the strikethrough must now only depend on resolution. However we have to keep
	// this logic because there is a consumer of issue-line-card in JSM that displays
	// the strikethrough based on status src/packages/servicedesk/post-incident-review/src/ui/primary-incident/index.tsx
	return statusCategory === DONE;
};
