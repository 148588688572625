import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	screenReaderFooterKeyResolved: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card.footer.screen-reader-footer-key-resolved',
		defaultMessage: 'Resolved issue: {issueKey}',
		description:
			'Screen reader text for strike through issue key text in footer of detail view card',
	},
	screenReaderFooterKeyDone: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card.footer.screen-reader-footer-key-done',
		defaultMessage: 'Done issue: {issueKey}',
		description: 'Aria label for strike through issue key text in footer of detail view card',
	},
});
