import React, {
	createContext,
	type ReactNode,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { fetchQuery, type RefetchFn, useRelayEnvironment } from 'react-relay';
import type { Subscription, GraphQLTaggedNode } from 'relay-runtime';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { addFilterToRecent } from '@atlassian/jira-issue-navigator-custom-filters/src/services/add-to-recent';
import { createAri } from '@atlassian/jira-platform-ari';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { FilterRefetchQuery } from '@atlassian/jira-relay/src/__generated__/FilterRefetchQuery.graphql';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

type FilterQueryContextProps = {
	filterQueryIsFetching: boolean;
	filterQueryIsNetworkError: boolean;
};

type FilterQueryProviderProps = {
	filterId: string | undefined;
	refetch: RefetchFn<FilterRefetchQuery>;
	query: GraphQLTaggedNode;
	children: ReactNode;
};

const FilterQueryContext = createContext<FilterQueryContextProps>({
	filterQueryIsFetching: false,
	filterQueryIsNetworkError: false,
});
FilterQueryContext.displayName = 'FilterQueryContext';

export const useFilterQuery = () => useContext(FilterQueryContext);

export const FilterQueryProvider = ({
	refetch,
	query,
	children,
	filterId,
}: FilterQueryProviderProps) => {
	const [filterQueryIsFetching, setFilterQueryIsFetching] = useState(false);
	const [filterQueryIsNetworkError, setFilterQueryNetworkError] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const activationId = useActivationId();
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();
	const inFlightRequest = useRef<Subscription | undefined>();

	useEffect(() => {
		if (filterId !== undefined && isFilterId(filterId)) {
			// Prevent race condition from multiple requests in progress
			// (ex. if user clicks quickly on different filters)
			inFlightRequest.current?.unsubscribe();

			const variables = {
				filterAri: createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'filter',
					resourceId: filterId,
					activationId,
				}),
				includeFilter: true,
			};

			setFilterQueryIsFetching(true);
			setFilterQueryNetworkError(false);

			inFlightRequest.current = fetchQuery<FilterRefetchQuery>(environment, query, variables, {
				fetchPolicy: 'store-or-network',
			}).subscribe({
				complete: () => {
					refetch(variables, { fetchPolicy: 'store-only' });
					setFilterQueryIsFetching(false);
				},
				error: () => {
					setFilterQueryIsFetching(false);
					setFilterQueryNetworkError(true);
				},
			});
		} else {
			// Need to refetch when filter is undefined so it reset filterData to empty
			refetch(
				{
					includeFilter: false,
					filterAri: '',
				},
				{ fetchPolicy: 'store-or-network' },
			);
		}
	}, [
		activationId,
		cloudId,
		environment,
		filterId,
		query,
		refetch,
		setFilterQueryIsFetching,
		setFilterQueryNetworkError,
	]);

	useEffect(() => {
		if (filterId !== undefined && isFilterId(filterId)) {
			const analyticsEvent = createAnalyticsEvent({});
			addFilterToRecent(filterId, analyticsEvent);
		}
	}, [filterId, createAnalyticsEvent]);

	const contextValue: FilterQueryContextProps = useMemo(
		() => ({
			filterQueryIsFetching,
			filterQueryIsNetworkError,
		}),
		[filterQueryIsFetching, filterQueryIsNetworkError],
	);

	return <FilterQueryContext.Provider value={contextValue}>{children}</FilterQueryContext.Provider>;
};
