import React from 'react';
import { styled } from '@compiled/react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import UnknownError from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/error/components/empty-search-error-colour';
import { useIntl } from '@atlassian/jira-intl';
import unknownErrorImg from '../../common/assets/unknown-error.svg';
import { ErrorDisplay } from '../../common/ui/error-display';
import { messages } from './messages';

const ErrorImage = () =>
	fg('move_issue_view_assets_into_central_location') ? (
		<UnknownError width={90} height={130} alt="" />
	) : (
		<Image src={unknownErrorImg} alt="" />
	);

export const UnknownErrorView = () => {
	const intl = useIntl();

	return (
		<ErrorDisplay
			header={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unknownErrorHeaderIssueTermRefresh
					: messages.unknownErrorHeader,
			)}
			description={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unknownErrorDescriptionIssueTermRefresh
					: messages.unknownErrorDescription,
			)}
			image={<ErrorImage />}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	height: '130px',
	width: '120px',
});
