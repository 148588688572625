// eslint-disable-next-line jira/restricted/react-component-props
import React, { useLayoutEffect, type ReactElement, type ComponentProps } from 'react';
import { stringify } from 'querystring';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { type CustomTriggerProps, DropdownItem } from '@atlaskit/dropdown-menu';
import ExportIcon from '@atlaskit/icon/core/migration/upload--export';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import AdvancedSearchButton from '@atlassian/jira-issue-navigator-action-advanced-search-button';
import AsyncExportIssues from '@atlassian/jira-issue-navigator-action-export-issues/src/ui/async.tsx';
import AsyncMeatballMenu from '@atlassian/jira-issue-navigator-action-meatball-menu/src/ui/async.tsx';
import { GO_TO_ALL_ISSUES } from '@atlassian/jira-issue-navigator-action-meatball-menu/src/ui/constants';
import QuickFiltersDropdown from '@atlassian/jira-issue-navigator-action-quick-filters';
import { ShareButton } from '@atlassian/jira-issue-navigator-action-share-button/src/ui/index.tsx';
import { FilterIconButton } from '@atlassian/jira-issue-navigator-actions-common/src/ui/filter-icon-button/index.tsx';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { SearchResultPageData } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import {
	markOnce,
	marks,
} from '@atlassian/jira-issue-navigator/src/common/utils/performance-analytics';
import ViewSwitcher from '@atlassian/jira-issue-navigator/src/ui/view-switcher';
import { useNaturalLanguageMode } from '@atlassian/jira-jql-builder-with-ai/src/controllers/natural-language-mode/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import messages from './messages';

type Props = {
	jql: string;
	filterId?: string;
	isModifiedFilter?: boolean;
	searchResultPageData: SearchResultPageData;
	isQuickFiltersDisabled?: boolean;
};

const PageActions = ({
	jql,
	filterId,
	isModifiedFilter,
	searchResultPageData,
	isQuickFiltersDisabled = false,
}: Props): ReactElement<ComponentProps<typeof ActionsContainer>> => {
	markOnce(marks.PAGE_ACTIONS_START);
	useLayoutEffect(() => {
		markOnce(marks.PAGE_ACTIONS_END);
	}, []);

	const isAnonymous = useIsAnonymous();

	const savedFilter = isFilterId(filterId) ? filterId : undefined; // filter out non-standard PIN named filters
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAdvanceSearchClickAnalytics = (
		e: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
	) => {
		const leftClick = e.type === 'contextmenu';
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		const withCmd = e.ctrlKey || e.metaKey;
		fireUIAnalytics(analyticsEvent, 'advancedSearchButton', {
			openedIn: leftClick && !withCmd ? 'sameTab' : 'newTab',
		});
	};

	const jqlString = isModifiedFilter ? jql : undefined;
	const advancedSearchHref = `/issues/?${stringify({ jql: jqlString, filter: filterId })}`;

	const additionalMenuItems = (
		<DropdownItem
			key={GO_TO_ALL_ISSUES}
			onClick={(e) => fireAdvanceSearchClickAnalytics(e)}
			href={advancedSearchHref}
			UNSAFE_shouldDisableRouterLink
		>
			{formatMessage(messages.allIssues)}
		</DropdownItem>
	);

	const customExportTrigger = (
		isOpen: boolean,
		triggerButtonProps: CustomTriggerProps<HTMLElement>,
	) => {
		const { triggerRef, ...props } = triggerButtonProps;
		return (
			<FilterIconButton
				customTriggerProps={{
					...props,
					ref: triggerRef,
				}}
				icon={ExportIcon}
				label={formatMessage(messages.exportIssuesButtonIconLabel)}
				isSelected={isOpen}
			/>
		);
	};

	const customExportDisabled = (
		<FilterIconButton
			isDisabled
			icon={ExportIcon}
			label={formatMessage(messages.exportIssuesButtonIconLabel)}
		/>
	);

	let enableQuickFilters = false;
	if (getWillShowNav4()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isNaturalLanguageMode] = useNaturalLanguageMode();
		enableQuickFilters = !isNaturalLanguageMode && !isQuickFiltersDisabled;
	}

	return (
		<ActionsContainer>
			{!isAnonymous && getWillShowNav3() && (
				<StyledPageAction>
					<ShareButton jql={jql} filterId={savedFilter} isModifiedFilter={isModifiedFilter} />
				</StyledPageAction>
			)}
			{getWillShowNav4() && enableQuickFilters && (
				<StyledPageAction>
					<QuickFiltersDropdown />
				</StyledPageAction>
			)}
			<StyledPageAction>
				<AsyncExportIssues
					jql={jql}
					filterId={savedFilter}
					isModifiedFilter={isModifiedFilter}
					showEcosystemApps={false}
					{...(getWillShowNav4() && {
						customTriggerButton: customExportTrigger,
						customDisabledButton: customExportDisabled,
					})}
				/>
			</StyledPageAction>
			{getWillShowNav3() && (
				<StyledPageAction>
					<AdvancedSearchButton
						jql={jql}
						filterId={savedFilter}
						isModifiedFilter={isModifiedFilter}
					/>
				</StyledPageAction>
			)}
			<StyledPageAction>
				<ViewSwitcher filterId={filterId} />
			</StyledPageAction>
			<StyledPageAction>
				<AsyncMeatballMenu
					jql={jql}
					filterId={savedFilter}
					searchResultPageData={searchResultPageData}
					additionalMenuItems={getWillShowNav4() && additionalMenuItems}
				/>
			</StyledPageAction>
		</ActionsContainer>
	);
};

export default PageActions;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPageAction = styled.div({
	margin: `0 ${token('space.050', '4px')}`,
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
});
