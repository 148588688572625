import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		/* eslint-disable-next-line jira/i18n/id-named-by-package */
		id: 'issue-table-column-picker.column-picker.no-options-message.title',
		defaultMessage: 'We couldn’t find the field you’re looking for.',
		description: 'The title for when no options could be loaded for a search input',
	},
	noOptionsPrimaryMessage: {
		id: 'issue-table-column-picker.common.no-options-message.no-options-primary-message',
		defaultMessage: "We couldn't find what you're looking for",
		description:
			'The text for when no matches are found in dropdown when a user tries to search for an option',
	},
	noOptionsSecondaryMessage: {
		id: 'issue-table-column-picker.common.no-options-message.no-options-secondary-message',
		defaultMessage: 'Try using different search terms.',
		description:
			'Helpful tip for when no matches are found in dropdown, prompting user to try a different search',
	},
});
