import React, { useCallback, useMemo, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	main_issueNavigator_Paginator$data as PaginatorData,
	main_issueNavigator_Paginator$key as PaginatorFragment,
} from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Paginator.graphql';
import PageText from '../../../../../common/ui/page-text';
import { useIssueSearchQuery } from '../../../../../services/issue-search-query';
import messages from './messages';

type PageCursors = NonNullable<PaginatorData['pageCursors']>;

const getLastPage = (pageCursors?: PageCursors | null): number | null | undefined => {
	if (pageCursors?.last) {
		return pageCursors.last.pageNumber;
	}

	if (pageCursors?.around?.length) {
		return pageCursors.around[pageCursors.around.length - 1]?.pageNumber;
	}

	return undefined;
};

const getPageCursor = (
	pageCursors: PageCursors | null,
	page: number,
): string | null | undefined => {
	const { first, last, around } = pageCursors ?? {};
	if (page === first?.pageNumber) return first?.cursor ?? undefined;
	if (page === last?.pageNumber) return last?.cursor ?? undefined;
	return (
		around?.find(
			(
				pageCursor:
					| {
							readonly cursor: string | null | undefined;
							readonly isCurrent: boolean | null | undefined;
							readonly pageNumber: number | null | undefined;
					  }
					| null
					| undefined,
			) => page === pageCursor?.pageNumber,
		)?.cursor ?? undefined
	);
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<StyledTooltipTag ref={ref as (instance: HTMLElement | null) => void} {...props} />
));

const Paginator = ({ issueResults }: { issueResults: PaginatorFragment }) => {
	const { formatMessage } = useIntl();
	const data = useFragment<PaginatorFragment>(
		graphql`
			fragment main_issueNavigator_Paginator on JiraIssueConnection {
				totalIssueSearchResultCount
				isCappingIssueSearchResult
				issueNavigatorPageInfo {
					firstIssuePosition
					lastIssuePosition
				}
				pageCursors(maxCursors: 7) {
					first {
						cursor
						pageNumber
						isCurrent
					}
					around {
						cursor
						pageNumber
						isCurrent
					}
					last {
						cursor
						pageNumber
						isCurrent
					}
				}
			}
		`,
		issueResults,
	);

	const currentPage =
		data.pageCursors?.around?.find(
			(
				pageCursor:
					| {
							readonly cursor: string | null | undefined;
							readonly isCurrent: boolean | null | undefined;
							readonly pageNumber: number | null | undefined;
					  }
					| null
					| undefined,
			) => pageCursor?.isCurrent,
		)?.pageNumber ?? 1;
	const lastPage = getLastPage(data.pageCursors) ?? 1;

	const { isFetching, isStalePage, onIssueSearchForPage } = useIssueSearchQuery();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onChange = useCallback(
		(pageNumber: number) => {
			// @ts-expect-error - Argument of type '{ readonly around: readonly ({ readonly cursor: string | null | undefined; readonly isCurrent: boolean | null | undefined; readonly pageNumber: number | null | undefined; } | null | undefined)[] | null | undefined; readonly first: { ...; } | ... 1 more ... | undefined; readonly last: { ...; } | ... 1 more ... | unde...' is not assignable to parameter of type '{ readonly around: readonly ({ readonly cursor: string | null | undefined; readonly isCurrent: boolean | null | undefined; readonly pageNumber: number | null | undefined; } | null | undefined)[] | null | undefined; readonly first: { ...; } | ... 1 more ... | undefined; readonly last: { ...; } | ... 1 more ... | unde...'.
			const pageCursor = getPageCursor(data.pageCursors, pageNumber);
			if (pageCursor == null) {
				return;
			}

			onIssueSearchForPage(pageCursor);
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'changed',
					actionSubject: 'pageNumber',
				}),
				'issueListPagination',
				{ currentPage, nextPage: pageNumber },
			);
		},
		[data.pageCursors, onIssueSearchForPage, createAnalyticsEvent, currentPage],
	);

	const onChangePrevious = useCallback(() => onChange(currentPage - 1), [currentPage, onChange]);
	const onChangeNext = useCallback(() => onChange(currentPage + 1), [currentPage, onChange]);

	const keyMap = useMemo(
		() => ({
			arrowleft: {
				callback: onChangePrevious,
				label: <div>{formatMessage(messages.nextPageShortcutLabel)}</div>,
			},
			arrowright: {
				callback: onChangeNext,
				label: <div>{formatMessage(messages.prevPageShortcutLabel)}</div>,
			},
		}),
		[formatMessage, onChangeNext, onChangePrevious],
	);

	const totalIssues = data.totalIssueSearchResultCount ?? 0;

	if (totalIssues === 0 || isFetching || isStalePage) {
		return null;
	}

	return (
		<StyledContainer data-testid="issue-navigator.ui.issue-results.detail-view.card-container.paginator">
			<Shortcuts keyMap={keyMap} />
			<Button
				iconBefore={<ChevronLeftLargeIcon label={formatMessage(messages.prevPageButton)} />}
				appearance="subtle"
				onClick={onChangePrevious}
				isDisabled={currentPage === 1}
				testId="issue-navigator.ui.issue-results.detail-view.card-container.paginator.prev-button"
			/>

			<PageAndCountContainer data-testid="issue-navigator.ui.issue-results.detail-view.card-container.paginator.div">
				<PageText
					startIssueRange={data.issueNavigatorPageInfo?.firstIssuePosition ?? 0}
					endIssueRange={data.issueNavigatorPageInfo?.lastIssuePosition ?? 0}
					total={totalIssues}
					countExceedsMaxResults={!!data.isCappingIssueSearchResult}
					loadedIssuesCount={-1}
					isInfiniteScrollEnabled={false}
				/>
			</PageAndCountContainer>

			{currentPage === lastPage && data?.isCappingIssueSearchResult ? (
				<Tooltip content={formatMessage(messages.disabledButtonTooltip)} tag={TooltipTag}>
					<DisabledButtonWithTooltip
						tabIndex={0}
						isDisabled
						aria-label={formatMessage(messages.disabledButtonTooltip)}
						data-testid="issue-navigator.ui.issue-results.detail-view.card-container.paginator.next-disabled-button"
					>
						<Button
							iconBefore={<ChevronRightLargeIcon label={formatMessage(messages.nextPageButton)} />}
							appearance="subtle"
							isDisabled
							onClick={onChangeNext}
							testId="issue-navigator.ui.issue-results.detail-view.card-container.paginator.next-button"
						/>
					</DisabledButtonWithTooltip>
				</Tooltip>
			) : (
				<Button
					iconBefore={<ChevronRightLargeIcon label={formatMessage(messages.nextPageButton)} />}
					appearance="subtle"
					isDisabled={currentPage === lastPage}
					onClick={onChangeNext}
					testId="issue-navigator.ui.issue-results.detail-view.card-container.paginator.next-button"
				/>
			)}
		</StyledContainer>
	);
};

export default Paginator;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageAndCountContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTooltipTag = styled.div({
	display: 'inline-flex',
});
