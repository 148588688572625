import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	previousIssueTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-tooltip',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "Previous issue ''{issueKey}'' (Type 'k')",
		description:
			'Tooltip shown on the button to select the previous issue followed by the keyboard shortcut to perform the action.',
	},
	previousIssueWithoutKeyTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-without-key-tooltip',
		defaultMessage: "Previous issue (Type 'k')",
		description:
			'Tooltip shown on the button to select the previous issue followed by the keyboard shortcut to perform the action.',
	},
	disabledButtonLabel: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.disabled-button-label',
		defaultMessage: 'No previous issue, select next issue',
		description:
			'Aria label when on first issue and the "Previous issue" button is disabled. The message tells users they are on the first issue and they can look at next issues instead.',
	},
});

export default messages;
