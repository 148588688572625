import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

export const EmptyList = ({ intl: { formatMessage } }: Intl) => {
	const noIssues = formatMessage(messages.noIssues);

	return (
		<>
			<Box xcss={ImageWrapperStyles}>
				<SearchNoResultsImage alt="" />
			</Box>
			<p>{noIssues}</p>
		</>
	);
};

/* eslint-disable-next-line jira/js/no-inject-intl-if-functional-component */
export default injectIntl(EmptyList);

const ImageWrapperStyles = xcss({
	height: '146px',
	width: '160px',
});
