import React, {
	type ComponentType,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { useGlobalScopeOptInFlag } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { jqlBuilder_issueNavigator_JQLBuilderWrapper_filter$key as JqlBuilderFilterFragmentType } from '@atlassian/jira-relay/src/__generated__/jqlBuilder_issueNavigator_JQLBuilderWrapper_filter.graphql';
import type { main_issueNavigator_Header_filter$key as HeaderFilterFragmentType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Header_filter.graphql';
import type { main_issueNavigator_IssueNavigatorUI_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_issueResults.graphql';
import type { main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey$key as JqlBuilderWithAiKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey.graphql';
import type { main_issueNavigator_IssueNavigatorUI_userPreferences$key as UserPreferencesFragmentKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_userPreferences.graphql';
import type { main_issueNavigator_IssueNavigatorUI_viewResult$key as ViewResultFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_viewResult.graphql';
import type { main_issueNavigator_ListView_filter$key as ListViewFilterFragmentType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import type { topBar_issueNavigator_filter$key as TopBarFilterFragment } from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_filter.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { PACKAGE_NAME } from '../common/constants';
import type {
	CustomHeaderProps,
	OverridableIssueTableProps,
	IssueNavigatorViewId,
} from '../common/types';
import { NoFilter } from '../common/ui/no-filter';
import { markOnce, marks } from '../common/utils/performance-analytics';
import { IssueSearchFail, IssueSearchSuccess } from '../controllers/issue-search-analytics';
import { useIsFullPageIssueAppMode } from '../controllers/selected-issue/facade.tsx';
import { useSelectedViewState } from '../controllers/selected-view-state';
import { useActiveJql } from '../services/active-jql';
import { useFilterQuery } from '../services/filter-query';
import { useIssueSearchQuery } from '../services/issue-search-query';
import FullPageIssueApp from './full-page-issue-app/main.tsx';
import Header from './header/main.tsx';
import IssueResults from './issue-results/main.tsx';
import JQLBuilder, { type OverridableJqlBuilderProps } from './jql-builder';
import messages from './messages';
import RegisterDotShortcut from './register-dot-shortcut';
import IssueNavigatorSpotlight from './spotlight';
import TopBar from './top-bar';

type Props = {
	issueResults: IssueResultsFragment | null;
	CustomHeader?: ComponentType<CustomHeaderProps>;
	ActionMenu?: ComponentType<CustomHeaderProps>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderSkeletonImage: ComponentType<Record<any, any>>;
	defaultJql: string;
	filter:
		| (HeaderFilterFragmentType &
				TopBarFilterFragment &
				JqlBuilderFilterFragmentType &
				ListViewFilterFragmentType)
		| null;
	viewResult: ViewResultFragment | null;
	userPreferences: UserPreferencesFragmentKey | null;
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
	onChangeFilter?: (filterId?: string) => void;
	onRefinement?: () => void;
	onChangeIssue: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: (jql: string, clearFilter?: boolean) => void;
	jqlBuilderProps?: OverridableJqlBuilderProps;
	jqlBuilderWithAiKey: JqlBuilderWithAiKey | null;
	isFeedbackButtonDisabled?: boolean;
	issueTableProps?: OverridableIssueTableProps;
};

export const IssueNavigatorUI = ({
	CustomHeader,
	ActionMenu,
	HeaderSkeletonImage,
	filter,
	onPageDataLoad,
	onChangeColumnConfiguration,
	onChangeJql,
	onChangeIssue,
	onChangeFilter,
	defaultJql,
	onRefinement,
	issueResults,
	viewResult,
	userPreferences,
	jqlBuilderProps,
	jqlBuilderWithAiKey,
	isFeedbackButtonDisabled,
	issueTableProps,
}: Props) => {
	markOnce(marks.ISSUE_NAVIGATOR_UI_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_NAVIGATOR_UI_END);
	}, []);
	const { formatMessage } = useIntl();
	const [caughtIssueSearchError, setCaughtIssueSearchError] = useState<Error>();

	const { filterId } = useActiveJql();
	const { filterQueryIsFetching } = useFilterQuery();

	const handleReset = useCallback(
		(isResetToFilter = false) => {
			if (isResetToFilter) {
				onChangeFilter && onChangeFilter();
			} else {
				onChangeJql && onChangeJql(defaultJql, true);
			}
		},
		[onChangeFilter, onChangeJql, defaultJql],
	);
	const onChange = useCallback(
		(jql: string) => {
			onRefinement && onRefinement();
			onChangeJql &&
				onChangeJql(jql, !filterQueryIsFetching && filter === null && isFilterId(filterId));
		},
		[filter, filterId, filterQueryIsFetching, onChangeJql, onRefinement],
	);

	const { searchKey } = useIssueSearchQuery();
	const previousData = usePrevious(searchKey);
	const hasSearchChanged = Boolean(searchKey && searchKey !== previousData);

	const [{ view: selectedView }] = useSelectedViewState();

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_issueResults on JiraIssueConnection {
				...topBar_issueNavigator_issueResults
				...main_issueNavigator_Header_issueResults
				...jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults
				...main_issueNavigator_IssueResults_issueResults
				...main_issueNavigator_FullPageIssueApp
				issueSearchError {
					__typename
				}
				totalIssueSearchResultCount
				isCappingIssueSearchResult
			}
		`,
		issueResults,
	);

	const viewResultData = useFragment<ViewResultFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_viewResult on JiraIssueSearchViewResult {
				...main_issueNavigator_IssueResults_viewResult
				... on QueryError {
					message
					extensions {
						statusCode
					}
					__typename
				}
			}
		`,
		viewResult,
	);

	const viewResultError = useMemo(
		() =>
			viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode !== undefined &&
			viewResultData?.extensions?.[0]?.statusCode !== null
				? new FetchError(viewResultData?.extensions?.[0]?.statusCode, viewResultData?.message ?? '')
				: undefined,
		[viewResultData],
	);

	const userPreferencesData = useFragment<UserPreferencesFragmentKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_userPreferences on JiraUserPreferences {
				...spotlight_issueNavigator_IssueNavigatorSpotlight
			}
		`,
		userPreferences,
	);

	const jqlBuilderWithAiData = useFragment<JqlBuilderWithAiKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey on JiraQuery {
				...jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey
			}
		`,
		jqlBuilderWithAiKey,
	);

	const shouldAnnounceInfo = useMemo(
		() =>
			issueResultsData !== null &&
			hasSearchChanged &&
			typeof issueResultsData.totalIssueSearchResultCount === 'number',
		[issueResultsData, hasSearchChanged],
	);

	const [isAiEnabled, setIsAiEnabled] = useState(false);
	const onToggleAi = useCallback((isEnabled: boolean) => {
		setIsAiEnabled(isEnabled);
	}, []);

	const noFilterReason: string | undefined = useMemo(() => {
		if (!filterQueryIsFetching && filter === null && isFilterId(filterId)) {
			return 'Filter query mismatch';
		}
		if (
			viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode === 404
		) {
			return 'Filter not found in view result';
		}
		return undefined;
	}, [
		filter,
		filterId,
		filterQueryIsFetching,
		viewResultData?.__typename,
		viewResultData?.extensions,
	]);

	const IssueNavigatorTopBar = useMemo(() => {
		const jqlBuilder = (
			<JQLBuilder
				onChangeJql={onChange}
				defaultJql={defaultJql}
				filter={filter}
				onReset={handleReset}
				onFilterSave={onChangeFilter}
				issueResults={issueResultsData}
				onToggleAi={onToggleAi}
				jqlBuilderWithAiKey={jqlBuilderWithAiData}
				jqlBuilderProps={jqlBuilderProps}
			/>
		);

		return (
			<>
				{ActionMenu && (
					<TopBar
						ActionMenu={ActionMenu}
						filter={filter}
						issueResults={issueResultsData}
						HeaderSkeletonImage={HeaderSkeletonImage}
						jqlBuilder={jqlBuilder}
					/>
				)}
				{CustomHeader && (
					<>
						<Header
							CustomHeader={CustomHeader}
							filter={filter}
							issueResults={issueResultsData}
							HeaderSkeletonImage={HeaderSkeletonImage}
						/>
						{jqlBuilder}
					</>
				)}
			</>
		);
	}, [
		defaultJql,
		filter,
		handleReset,
		issueResultsData,
		jqlBuilderProps,
		jqlBuilderWithAiData,
		onChange,
		onChangeFilter,
		onToggleAi,
		ActionMenu,
		CustomHeader,
		HeaderSkeletonImage,
	]);

	let IssueNavigatorContent = useMemo(
		() => (
			<IssueNavigatorContentContainer selectedView={selectedView}>
				{IssueNavigatorTopBar}
				{(!filterQueryIsFetching && filter === null && isFilterId(filterId)) ||
				(viewResultData?.__typename === 'QueryError' &&
					viewResultData?.extensions?.[0]?.statusCode === 404) ? (
					<NoFilter reason={noFilterReason} />
				) : (
					<>
						<AnnouncerV2
							message={formatMessage(
								issueResultsData?.isCappingIssueSearchResult
									? messages.screenReaderSearchResultMoreInfo
									: messages.screenReaderSearchResultInfo,
								{
									total: issueResultsData?.totalIssueSearchResultCount,
								},
							)}
							shouldAnnounce={shouldAnnounceInfo}
							testId="issue-navigator.ui.search-announcer"
						/>
						<IssueResults
							onChangeColumnConfiguration={onChangeColumnConfiguration}
							onChangeJql={onChangeJql}
							onCaughtIssueSearchError={setCaughtIssueSearchError}
							issueResults={issueResultsData}
							viewResult={viewResultData}
							isFeedbackButtonDisabled={isFeedbackButtonDisabled}
							issueTableProps={issueTableProps}
							filter={filter}
						/>
					</>
				)}
				<IssueNavigatorSpotlight
					hasIssueResults={!!issueResultsData?.totalIssueSearchResultCount}
					userPreferences={userPreferencesData}
					isAiEnabled={isAiEnabled}
				/>
			</IssueNavigatorContentContainer>
		),
		[
			IssueNavigatorTopBar,
			selectedView,
			filter,
			issueResultsData,
			filterQueryIsFetching,
			filterId,
			noFilterReason,
			formatMessage,
			shouldAnnounceInfo,
			onChangeColumnConfiguration,
			onChangeJql,
			viewResultData,
			isFeedbackButtonDisabled,
			issueTableProps,
			userPreferencesData,
			isAiEnabled,
		],
	);

	const isFullPageIssueAppMode = useIsFullPageIssueAppMode();

	if (isFullPageIssueAppMode) {
		IssueNavigatorContent = (
			<FullPageIssueApp onChangeIssue={onChangeIssue} issueResults={issueResultsData} />
		);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	ff('nin.global-scope_aqec8') && useGlobalScopeOptInFlag();

	const isFailedIssueSearch =
		!issueResultsData ||
		issueResultsData.issueSearchError?.__typename === 'JiraServerError' ||
		caughtIssueSearchError !== undefined ||
		!viewResultData ||
		(viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode !== 404);

	return useMemo(
		() => (
			<>
				{IssueNavigatorContent}
				<RegisterDotShortcut />
				<FireScreenAnalytics />
				{isFailedIssueSearch ? (
					<IssueSearchFail
						location={`${PACKAGE_NAME}.ui`}
						error={
							caughtIssueSearchError ||
							viewResultError ||
							new Error('There was an error searching for issues')
						}
						onPageDataLoad={onPageDataLoad}
						attributes={{
							isAiEnabled,
						}}
					/>
				) : (
					<IssueSearchSuccess
						onPageDataLoad={onPageDataLoad}
						attributes={{
							isAiEnabled,
						}}
					/>
				)}
			</>
		),
		[
			IssueNavigatorContent,
			isFailedIssueSearch,
			caughtIssueSearchError,
			viewResultError,
			onPageDataLoad,
			isAiEnabled,
		],
	);
};

// re-render regression detection
IssueNavigatorUI.whyDidYouRender = true;

export default memo<
	JSX.LibraryManagedAttributes<typeof IssueNavigatorUI, ComponentProps<typeof IssueNavigatorUI>>
>(IssueNavigatorUI);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueNavigatorContentContainer = styled.div<{ selectedView: IssueNavigatorViewId }>(
	{
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		margin: `0 ${token('space.400', '32px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ selectedView }) =>
		selectedView !== 'detail' && {
			paddingBottom: token('space.250', '20px'),
			boxSizing: 'border-box',
		},
);
